@use 'helpers' as *;

.root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: calc(100vh - 70px);
    max-height: calc(100vh - 70px);
    padding: 0 16px;
    overflow-y: auto;
    overflow-x: hidden;

    .chatInputContainer {
        padding: 8px 16px;
        background: $elev-08;
        border-radius: 18px 18px 0 0;
        box-shadow: 0 -3px 3px -1px #000;
        position: absolute;
        bottom: -75px;
        right: 8px;
        left: 8px;
    }
}

:global(.App .MuiFab-root) {
    display: none;
}
