@use 'helpers' as *;

.root {
    @include scrollbar-styles;
    @include standard-font;
    background: $elev-02;

    .timelineIcon {
        width: 24px;
        height: 24px;
        color: $chat-button-color;
    }

    .timelineFilters {
        position: sticky;
        padding: 4px 6px;
        top: 0;
        background: $elev-02;
        display: flex;
        flex-direction: column;
    }
    
}

.timelineCollapse {
    position: absolute;
    width: 100vw;
    top: 50px;
    right: 0;
    background: #000000ea;
    display: flex;
    flex-direction: column;

    .content {
        min-height: calc(100vh - 50px);
        flex-grow: 1;
        padding: 0px 16px 24px 0px;
        background: $elev-02;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 16px;
    }

    @include sm-device {
        @include card-box-shadow-shallow-left;
        max-width: 500px;
    }
}

.eventContainer {
    flex-grow: 1;
    padding-bottom: 16px;
}

.buttonContainer {
    display: flex;
    height: 20px;
    gap: 8px;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin: 0px;

    > h6 {
        @include standard-font;
        font-size: 1.2rem;
        margin: 0px;
    }
}

.closeButton:global(.MuiIconButton-root) {
    color: $highlight-1;
    margin: auto auto 54px;
}
