@use '../../../styles/helpers' as *;

.root {
    display: flex;
    height: 100vh;

    > div {
        margin: auto;
        display: flex;
        flex-direction: column;
        @include sm-device {
            flex-direction: row;
        }
    }
}
