$elev-00: #121212;
$elev-01: #1d1d1d;
$elev-02: #212121;
$elev-03: #242424;
$elev-04: #262626;
$elev-06: #2c2c2c;
$elev-08: #2d2d2d;
$elev-12: #323232;
$elev-16: #353535;
$elev-24: #383838;

$elev-01-translucent: rgba(255, 255, 255, 0.05);
$elev-02-translucent: rgba(255, 255, 255, 0.07);
$elev-03-translucent: rgba(255, 255, 255, 0.08);
$elev-04-translucent: rgba(255, 255, 255, 0.09);
$elev-06-translucent: rgba(255, 255, 255, 0.11);
$elev-08-translucent: rgba(255, 255, 255, 0.12);
$elev-12-translucent: rgba(255, 255, 255, 0.14);
$elev-16-translucent: rgba(255, 255, 255, 0.15);
$elev-24-translucent: rgba(255, 255, 255, 0.16);

$add-button-color: #8ffaa1;
$anchor-color: #bfe1f1;
$autocomplete-chip-color: #4e525a;
$autocomplete-selected: #4e525a;
$busy-circular-progress-color: #dec878;
$chat-anchor-color: #bfe1f1;
$chat-bubble-alt: #343a45;
$chat-bubble: #4e525a;
$chat-button-color: #ffffff;
$close-button-color: #d0d0d0;
$document-action-button-background: #515151;
$document-action-button-color: #fafafa;
$form-control-underline: #5a5a5aaa;
$highlight-1: #fad77b;
$highlight-2: #d8eeff;
$highlight-3: #c6ccd0;
$kpi-grid: #202124;
$outlined-text-field-border-color: #f1ce7099;
$negative: #ff8b8b;
$primary-color: #fff;
$print-button-color: #8ffaa1;
$print-correspondence-font-color: rgba(0, 0, 0, 0.54);
$print-preview-font-color: #121212;
$print-checkbox-color: #515151;
$required-field-label-color: #8ffaa1;
$save-button-color: #7ccdf6;
$scroll-bar-thumb-color: #425e8f;
$scroll-bar-track-color: #28292e;
$secondary-color: #ff8b8b;
$section-divider-color: #404040;
$surrounding-divider-color: #c8ac62;
$table-body-grid-line: #5a5a5a33;
$table-head-grid-line: rgba(162, 162, 162, 0.2);
$table-row-hover: #2c2f45;
$timestamp: #ababab;
$positive: #8ffaa1;
$underlay-bg-color: #242529;
$vertical-divider: #5a5a5a;

:export {
    tableRowSelected: $table-row-hover;
}
