@use 'helpers' as *;

.root {
    @include standard-font;
    width: 100%;
    overflow: auto;
    overflow-wrap: break-word;
    overflow-y: scroll;
    height: 170px;
}

.list {
    @include standard-font;
    margin: 0;
    padding: 0 8px;

    p {
        margin-block: 0;
    }

    :global(.MuiListItemIcon-root) {
        color: $primary-color;
        font-size: 1.1rem;
    }
    :global(.MuiListItem-divider) {
        border-color: $vertical-divider;
        padding: 8px 16px;
    }
}

.milestoneListItem {
    padding: 0;
    margin: 0;

    .description {
        color: $highlight-1;
    }
}
