@use 'helpers' as *;

.root {
    display: flex;
    flex-direction: column;
    width: 100%;

    .healthReportCardTitle {
        @include standard-font;
        margin: 0 auto;
        font-size: 0.9rem;
        font-weight: 400;
        color: $highlight-1;

        .link {
            color: $anchor-color;
            cursor: pointer;
        }
    }

    .card {
        background: $elev-02;
        padding: 20px;
        display: flex;
        flex-direction: column;
        margin: auto 0;
    }

    :global(.katex) {
        font-size: clamp(0.5rem, 2.25vw, 0.7rem);
    }

    .scoreRoot {
        .calculationContainer {
            text-align: center;
        }

        .resultContainer {
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .columnContainer {
                display: flex;
                flex-direction: column;
                gap: 16px;

                p {
                    font-size: 1rem;
                    text-align: center;
                }

                .score {
                    height: 60px;
                    font-size: 60px;
                    line-height: 55px;
                    text-align: center;

                    @include xl-device {
                        font-size: clamp(1rem, 2vw, 1.5rem);
                    }
                }

                svg {
                    width: 60px;
                    height: 60px;
                }
            }

            :global(.MuiDivider-root) {
                height: 150px;
                background: $vertical-divider;
            }
        }
    }
}
