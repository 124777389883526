@use 'helpers' as *;

.root {
    position: relative;
    height: 32px;
}

:global(.MuiCircularProgress-svg) {
    color: $busy-circular-progress-color;
}
