@use 'helpers' as *;

.Title {
    @include standard-font;
    font-size: 1.2rem;
    color: $highlight-1;

    &:global(.MuiTypography-root) {
        margin-bottom: 16px;
    }
}

.Address {
    &:global(.MuiTypography-root) {
        margin-bottom: 16px;
    }

    a {
        @include standard-font;
        font-size: 0.9rem;
        color: $primary-color;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.Phone {
    &:global(.MuiTypography-root) {
        margin-bottom: 16px;
    }

    a {
        @include standard-font;
        font-size: 0.9rem;
        color: $anchor-color;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.Email {
    a {
        @include standard-font;
        font-size: 0.9rem;
        color: $anchor-color;
    }
}
