@use '../../../styles/helpers' as *;

.root {
    display: flex;
    flex-direction: column;
    background-color: $elev-04;
    color: $highlight-1;
    padding: 0 32px 32px;
    min-width: 280px;
    align-items: center;

    :global(.MuiFormControl-root) {
        margin-bottom: 16px;
    }

    @include sm-device {
        padding: 64px;
    }
}

.logo {
    height: 64px;
    padding: 32px;
    @include sm-device {
        padding: 0 0 32px;
    }
}

.loginButton {
    &:global(.MuiButton-root) {
        width: 150px;
        height: 32px;
        margin-bottom: 16px;
        @include draw-meet-border-animation($save-button-color);
    }
}

.input {
    max-width: 300px;
    margin: 8px auto;
}

.linkLabel {
    font-size: clamp(0.5em, 2vw, 0.7em);
    margin: 16px auto 4px;
}

.link {
    margin: auto;
    color: $anchor-color;
    font-size: clamp(0.5em, 2vw, 0.7em);
}

.forgotPassword {
    display: flex;

    > div {
        color: $anchor-color;
        margin: 0 auto;
        font-size: clamp(0.5em, 2vw, 0.7em);
        text-decoration: underline;
    }
}
