@use 'helpers' as *;

.root {
    &:global(.MuiFormControl-root) {
        margin-bottom: 16px;
    }

    :global(.MuiFormLabel-root) {
        opacity: 90%;
        color: $highlight-1;
        font-size: clamp(0.8rem, 2vw, 1rem);

        &:global(.Mui-focused) {
            color: $highlight-1;
            opacity: 1;
        }
    }

    :global(.MuiInputBase-input) {
        @include standard-font-family;
    }

    .searchButton {
        margin-left: 15px;
    }
}
