@use 'helpers' as *;

.root {
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}

.projectLogTableTitleColumn {
    @include standard-font;
    font-size: inherit;
    color: $anchor-color;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
