@use 'sass:math';
@use 'colors';

$screen-sm-min: 600px;
$screen-md-min: 960px;
$screen-lg-min: 1200px;
$screen-xl-min: 1960px;
$screen-xxl-min: 3840px;

@mixin sm-device {
    @media (min-width: $screen-sm-min) {
        @content;
    }
}

@mixin md-device {
    @media (min-width: $screen-md-min) {
        @content;
    }
}

@mixin lg-device {
    @media (min-width: $screen-lg-min) {
        @content;
    }
}

@mixin xl-device {
    @media (min-width: $screen-xl-min) {
        @content;
    }
}

@mixin xxl-device {
    @media (min-width: $screen-xxl-min) {
        @content;
    }
}

@mixin standard-font-family {
    font-family: 'Gotham Book', Roboto, Helvetica, sans-serif;
    color: colors.$primary-color;
}

@mixin standard-font {
    @include standard-font-family;
    letter-spacing: 1px;
}

@mixin bold-header {
    font-family: 'Couture', Roboto, sans-serif;
    color: colors.$primary-color;
    font-size: 1.2rem;
    letter-spacing: 3px;
}

@mixin caption {
    @include standard-font;
    font-size: 0.8rem;
}

@mixin flat-button {
    @include standard-font;
    border-radius: 0;
    border: none;
    height: 100%;
    font-size: 0.75rem;
    box-shadow: none;
    text-transform: none;
}

@mixin popper {
    $popper-bg-color: colors.$elev-16;
    border-radius: 0;
    background-color: $popper-bg-color;
    box-shadow: 5px 5px 10px -3px #000;
    :global(.MuiMenuItem-root) {
        @include flat-button;
    }
    .arrow {
        overflow: hidden;
        position: absolute;
        width: 1em;
        height: 0.71em;
        box-sizing: border-box;
        color: $popper-bg-color;
        top: 0;
        left: 0;
        margin-top: -0.71em;
        margin-left: 4px;
        margin-right: 4px;

        &::before {
            content: '';
            margin: auto;
            display: block;
            width: 100%;
            height: 100%;
            background-color: $popper-bg-color;
            transform: rotate(45deg);
            transform-origin: 0 100%;
        }
    }
}

@mixin menuContainer {
    width: 172px; //2px extra to account for border-left
    display: flex;
    flex-wrap: wrap;

    a {
        text-decoration: none;
        color: inherit;
    }
}

@mixin menuItem($n) {
    $hover-transition: all 0.3s ease-in-out;
    width: #{$n * 85}px;
    min-width: #{$n * 85}px;
    height: 85px;
    border-bottom: 1px solid #202020;
    border-left: 1px solid #202020;
    display: flex;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;

    p,
    span {
        position: relative;
        display: block;
        margin: auto;
        text-align: center;
        padding: 12px 0;
        font-size: 0.65rem;

        &::after {
            position: absolute;
            content: '';
            top: 100%;
            left: 10%;
            width: 80%;
            height: 2px;
            background: colors.$highlight-1;
            transform: scaleX(0);
            transform-origin: right;
            transition: transform 0.75s;
        }
    }

    &:hover {
        background-color: colors.$elev-06;

        p,
        span {
            color: colors.$highlight-1;
            font-size: 0.75rem;
            transition: $hover-transition;

            &::after {
                transform: scaleX(1);
                transform-origin: left;
            }
        }
    }
}

@mixin card-box-shadow {
    box-shadow: 2px 2px 4px 1px #000;
}

@mixin card-box-shadow-shallow {
    box-shadow: 4px 4px 3px -3px #000;
}

@mixin button-box-shadow {
    box-shadow: 4px 4px 3px -3px #000;
}

@mixin opaque-glass {
    background: #000000cc;
    backdrop-filter: blur(2px);
}

@mixin card-box-shadow-shallow-left {
    box-shadow: -4px 4px 3px -3px #000;
}

@mixin surrounding-divider($width-of-gap) {
    &:before,
    &:after {
        content: '';
        position: absolute;
        background: colors.$surrounding-divider-color;
        top: 50%;
        height: 1px;
        transform: translateY(-50%);
    }

    &:before {
        right: 0;
        left: calc(50% + #{math.div($width-of-gap, 2)}px);
    }

    &:after {
        right: calc(50% + #{math.div($width-of-gap, 2)}px);
        left: 0;
    }
}

@mixin tapered-surrounding-divider($width-of-gap) {
    @include surrounding-divider($width-of-gap);

    &:before {
        background-image: linear-gradient(to right, transparent, colors.$elev-04);
    }

    &:after {
        background-image: linear-gradient(to left, transparent, colors.$elev-04);
    }
}

@mixin scrollbar-styles {
    // Scrollbar styles
    .Component-horizontalScrollContainer-5 ::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb {
        border: none;
        background: colors.$scroll-bar-thumb-color;
    }

    ::-webkit-scrollbar {
        max-width: 10px;
        max-height: 10px;
        background-color: transparent;
    }

    /* set button(top and bottom of the scrollbar) */
    ::-webkit-scrollbar-button {
        display: none;
    }

    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
}

@mixin sun-editor-defaults {
    .sunEditorContainer {
        @include standard-font;
        color: colors.$highlight-1;
        margin-bottom: 8px;

        > p {
            font-size: clamp(0.6rem, 2vw, 0.7rem);
        }
    }

    :global(.sun-editor) {
        @include standard-font;
        border: none;
        background: none;

        button {
            color: colors.$primary-color;
        }

        :global(.se-toolbar) {
            background: colors.$elev-16;
            outline: none;
        }

        :global(.sun-editor-editable) {
            @include standard-font;
            background: colors.$elev-08;
            padding: 8px;
        }

        :global(.se-resizing-bar) {
            background: colors.$elev-16;
            border-top: 1px solid colors.$table-body-grid-line;
        }
    }
}

@mixin date-picker-defaults {
    :global(.MuiPickersModal-dialogRoot) {
        background: colors.$elev-02;
    }
}

@mixin dialog-module-form {
    @include standard-font;
    @include sun-editor-defaults;

    background: inherit;

    .required {
        > label {
            color: colors.$required-field-label-color;
        }
    }

    p.required {
        color: colors.$required-field-label-color;
    }

    :global(.MuiInputBase-input) {
        @include standard-font;
        font-size: 14px;
    }

    :global(.MuiInputBase-root) {
        &:global(.Mui-disabled) {
            :global(.MuiAutocomplete-endAdornment) {
                display: none;
            }
        }

        * {
            color: colors.$primary-color;
        }

        :global(.MuiInputBase-multiline) {
            padding: 6px 0 0;
        }
    }

    :global(.MuiFormControl-root) {
        margin-bottom: 12px;
    }

    :global(.MuiFormLabel-root) {
        @include standard-font;
        color: colors.$highlight-1;
        font-size: clamp(0.7rem, 3vw, 0.9rem);
    }
    :global(.MuiGrid-spacing-xs-2) {
        > :global(.MuiGrid-item) {
            padding: 0 8px;
        }
    }

    :global(.MuiChip-root) {
        background: colors.$autocomplete-chip-color;
        font-size: clamp(0.5rem, 2vw, 0.8rem);

        &:global(.Mui-disabled) {
            opacity: 1;

            :global(.MuiChip-deleteIcon) {
                display: none;
            }
        }
    }

    .lastModified {
        font-size: 0.6rem;
        font-style: italic;
        color: colors.$highlight-1;
        text-align: center;
    }

    .attachmentList {
        > p {
            color: colors.$highlight-1;
        }

        > ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }
}

@mixin arrow-bottom($color) {
    position: absolute;
    font-size: 7px;
    width: 3em;
    height: 1em;
    bottom: 0;
    left: 50%;
    margin-bottom: -0.9em;

    &::before {
        content: '';
        margin: auto;
        display: block;
        width: 0;
        height: 0;
        border-width: 1em 1em 0 1em;
        border-color: $color transparent transparent transparent;
        border-style: solid;
    }
}

@mixin arrow-top-right($color) {
    position: absolute;
    right: 0;
    margin-right: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-left: 10px solid $color;
    border-bottom: 8px solid transparent;
}

//animations

@mixin draw-meet-border-animation($border-color) {
    // Required, since we're setting absolute on pseudo-elements
    @include animation-button-base($border-color);
    @include draw($border-color);
    &:hover {
        color: $border-color;
        background: rgba($border-color, 0.05);
    }

    &::after {
        bottom: 0;
        left: 0;
    }

    // Change colors
    &:hover::before {
        border-top-color: $border-color;
        border-right-color: $border-color;
    }

    &:hover::after {
        border-bottom-color: $border-color;
        border-left-color: $border-color;
        transition: // Animate height first, then width
            width 0.1s ease-out, height 0.1s ease-out 0.1s;
    }
}

@mixin animation-button-base($color) {
    position: relative;
    box-sizing: border-box;
    border: 0;
    vertical-align: middle;
    &::before,
    &::after {
        box-sizing: inherit;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
    }
    //box-shadow: inset 0 0 0 1px #DEDEDE;
    //box-shadow: inset 0 0 0 1px rgba($color, 0.35);
    border-radius: 0;
}

@mixin draw($draw-color) {
    transition: color 0.25s;

    &::before,
    &::after {
        // Set border to invisible, so we don't see a 4px border on a 0x0 element before the transition starts
        border: 1px solid transparent;
        width: 0;
        height: 0;
    }

    // This covers the top & right borders (expands right, then down)
    &::before {
        top: 0;
        left: 0;
    }

    // And this the bottom & left borders (expands left, then up)
    &::after {
        bottom: 0;
        right: 0;
    }

    &:hover {
        color: $draw-color;
    }

    // Hover styles
    &:hover::before,
    &:hover::after {
        width: 100%;
        height: 100%;
    }

    &:hover::before {
        border-top-color: $draw-color; // Make borders visible
        border-right-color: $draw-color;
        transition: width 0.15s ease-out,
            // Width expands first
            height 0.15s ease-out 0.15s; // And then height
    }

    &:hover::after {
        border-bottom-color: $draw-color; // Make borders visible
        border-left-color: $draw-color;
        transition: border-color 0s ease-out 0.5s,
            // Wait for ::before to finish before showing border
            width 0.25s ease-out 0.5s,
            // And then expanding width
            height 0.15s ease-out 0.5s; // And finally height
    }
}

@mixin draw-border-animation($border-color) {
    @include animation-button-base($border-color);
    @include draw($border-color);

    &:hover::before {
        border-top-color: $border-color; // Make borders visible
        border-right-color: $border-color;
        transition: width 0.25s ease-out,
            // Width expands first
            height 0.25s ease-out 0.25s; // And then height
    }

    &:hover::after {
        border-bottom-color: $border-color; // Make borders visible
        border-left-color: $border-color;
        transition: border-color 0s ease-out 0.5s,
            // Wait for ::before to finish before showing border
            width 0.25s ease-out 0.5s,
            // And then exanding width
            height 0.25s ease-out 0.75s; // And finally height
    }
}

@mixin draw-meet-border-animation($border-color) {
    // Required, since we're setting absolute on pseudo-elements
    @include animation-button-base($border-color);
    @include draw($border-color);

    &:hover {
        color: $border-color;
        background: rgba($border-color, 0.05);
    }

    &::after {
        bottom: 0;
        left: 0;
    }

    // Change colors
    &:hover::before {
        border-top-color: $border-color;
        border-right-color: $border-color;
    }

    &:hover::after {
        border-bottom-color: $border-color;
        border-left-color: $border-color;
        transition: // Animate height first, then width
            width 0.25s ease-out, height 0.25s ease-out 0.25s;
    }
}

@mixin shine-animation($color) {
    &::after {
        content: '';
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);

        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.13) 0%,
            rgba(255, 255, 255, 0.13) 77%,
            rgba(255, 255, 255, 0.5) 92%,
            rgba(255, 255, 255, 0) 100%
        );
    }
    &:hover {
        background: rgba($color, 0.9);
        opacity: 1;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        div {
            color: #000;
        }
        transition-property: background-color, color;
        transition-duration: 0.5s;
        transition-timing-function: ease-in-out;
    }

    &:hover::after {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }

    &:active:after {
        opacity: 0;
    }
}
