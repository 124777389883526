@use 'helpers' as *;

.dashboardContent {
    padding: 24px 18px;
    overflow-x: hidden;

    @include md-device {
        padding: 40px 5%;
    }
    @include xl-device {
        padding: 40px 8%;
    }
    @include xxl-device {
        padding: 40px 13%;
    }
}

.flexContainer {
    display: flex;
    gap: 16px;
    justify-content: center;
    @include sm-device {
        padding: 16px;
    }
}
