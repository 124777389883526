@use 'helpers' as *;

.root {
    padding: 24px 16px 48px;
    @include sm-device {
        padding: 16px 32px 48px;
    }
    @include md-device {
        padding: 16px 48px 48px;
    }
}
