@use 'helpers' as *;

.tableRoot {
    width: 100%;
    padding-bottom: 40px;
    
    &.nodata {
        height: 260px;
    }

    &.bigdata {
        height: 520px;
    }

    .chip {
        &:global(.MuiChip-root) {
            @include standard-font;
            font-size: 10px;
        }
    
        @include card-box-shadow-shallow;
    }

    :global(.MuiDataGrid-root) {
        background: $elev-02;
        @include standard-font;
        @include card-box-shadow;
        border: 1px solid #000;
        border-radius: 12px;

        :global(.MuiDataGrid-cell) {
            padding: 0 16px;
            font-size: 12px;
        }

        @include scrollbar-styles;
        ::-webkit-scrollbar {
            max-height: 10px;
            height: 10px;
            max-width: 10px;
            width: 10px;
        }
        ::-webkit-scrollbar-button {
            display: none;
        }
    
    }

    :global(.MuiDataGrid-columnHeaders) {
        @include standard-font;
        font-size: 13px;
        color: $highlight-1;
        background: $elev-12;
        border-bottom: 1px solid $highlight-1;
        @include card-box-shadow;
        z-index: 9;
        border-radius: 12px 12px 0 0;
    }

    :global(.MuiDataGrid-footerContainer) {
        background: $elev-12;
        border-top: 1px solid $highlight-1;
        border-radius: 0 0 12px 12px;
    }
}

.row {
    cursor: pointer;

    &.readOnly {
        color: rgb(194, 193, 193);
    }

    &.treeBranch {
        background: #161618;
        font-style:oblique;
        color: rgb(207, 204, 204) !important;
        font-weight: 100 !important;
    }

    &.actionRequired {
        color:  #90caf9;
        font-style: o;
        font-weight: 600; 
    }

    &.actionable {
        color:  #FFF;
    }

    &.editable {
        color:  #FFF;
    }

    &.actionableEditable {
        color:  #FFF;
    }
    // color: row.editable ? '#FFF' : '#FAD77B',
    // background: row.descendent ? '#161618' : 'inherit',
}

.searchContainer {
    width: 280px;
    min-width: 280px;
    margin-right: 20px !important;

    .searchBar {
        padding: 4px 4px 4px 12px;
    }
}