@font-face {
    font-family: 'Gotham Book';
    font-weight: 400;
    src: local('Gotham Book'), url(../../assets/fonts/Gotham-Book.ttf) format('truetype');
}

@font-face {
    font-family: 'Gotham Book';
    font-weight: 700;
    src: local('Gotham Bold'), url(../../assets/fonts/Gotham-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Couture';
    font-weight: 700;
    src: local('Couture'), url(../../assets/fonts/couture-bld.otf) format('opentype');
}

@font-face {
    font-family: 'Couture';
    font-weight: 700;
    font-style: italic;
    src: local('Couture'), url(../../assets/fonts/couture-bldit.otf) format('opentype');
}
