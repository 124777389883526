@use 'helpers' as *;

.root {
    position: relative;
    padding-bottom: 16px;
    background: inherit;
    display: flex;
    @include sm-device {
        padding-bottom: 0;
    }

    :global(.swiper-container) {
        width: 420px;

        @include sm-device {
            width: 456px;
        }

        :global(.swiper-wrapper) {
            padding-bottom: 6px;
        }

        :global(.swiper-pagination) {
            position: absolute;
            bottom: 16px;
            left: 0;
            width: 100%;
            text-align: center;
            transform: translate3d(0, 0, 0);
            z-index: 10;

            :global(.swiper-pagination-bullet) {
                background: #585858;
                opacity: 1;
                width: 6px;
                height: 6px;
                display: inline-block;
                border-radius: 100%;
                margin: 0 4px;

                &:global(.swiper-pagination-bullet-active) {
                    background: $highlight-1;
                }
            }
        }
    }
}

.swiperButtonPrev,
.swiperButtonNext {
    position: absolute;
    top: 12px;
    z-index: 10;
    display: flex;
    align-items: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;

    :global(.MuiSvgIcon-root) {
        color: $anchor-color;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }

    &[aria-disabled='true'] {
        opacity: 0;
        cursor: auto;
        pointer-events: none;
    }
}

.swiperButtonPrev,
.swiper-container-rtl .swiperButtonNext {
    left: 8px;
}

.swiperButtonNext,
.swiper-container-rtl .swiperButtonPrev {
    right: 16px;
}
