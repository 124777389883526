@use 'helpers' as *;

.root {
    @include scrollbar-styles;
    @include standard-font;
    background: $elev-02;

    .chatIcon {
        color: $chat-button-color;
        width: 28px;
        height: 28px;
    }

    .chatCollapse {
        position: absolute;
        width: 100vw;
        top: 50px;
        right: 0;
        background: #000000ea;
        display: flex;
        flex-direction: column;
        @include sm-device {
            max-width: 500px;
            @include card-box-shadow-shallow-left;
        }
    
        .content {
            min-height: calc(100vh - 96px);
            max-height: calc(100vh - 96px);
            background: $elev-02;
            padding: 0px 16px;
            overflow-y: auto;
            overflow-x: hidden;
        }
    
        .chatInputContainer {
            padding: 8px 16px;
            background: $elev-08;
            border-radius: 18px 18px 0 0;
            box-shadow: 0 -3px 3px -1px #000;
            position: absolute;
            bottom: 0;
            right: 8px;
            left: 8px;
        }
    }

    :global(.MuiBadge-badge) {
        min-width: 18px;
        min-height: 18px;
        height: 18px;
        font-size: 11px;
        padding: 2px 6px 2px 5px;
        z-index: 0;
        top: 0;
    }
}



.buttonContainer {
    display: flex;
    height: 20px;
    gap: 8px;
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin: 0px;

    > h6 {
        @include standard-font;
        font-size: 1.2rem;
        margin: 0px;
    }
}
