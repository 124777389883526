@use 'helpers' as *;

.root {
    width: 100%;
    margin-bottom: 8px;
    position: relative;

    :global(.MuiInputBase-root) {
        @include standard-font;
        font-size: 0.8rem;
    }

    :global(.MuiInput-underline) {
        border-bottom: 1px solid $highlight-1;
        &:after {
            border-bottom: 2px solid $highlight-1;
        }
    }
}
