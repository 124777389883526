@use 'helpers' as *;

.root {
    background: $elev-06-translucent;
    backdrop-filter: blur(3px);
    border-radius: 6px;
    padding: 4px 16px;
    margin-bottom: 12px;
    position: relative;

    .notificationIcon {
        z-index: 1;
        height: 24px;
        position: absolute;
        top: -16px;
        left: 4px;
        cursor: pointer;
        opacity: 1;
    }
    .ackedTeamIcon {
        z-index: 0;
        position: absolute;
        color: $anchor-color;
        top: -10px;
        left: 26px;
        
    }
    .ackedTeamInfoIcon {
        z-index: 0;
        position: absolute;
        color: $anchor-color;
        top: -18px;
        left: 42px;
        
    }
    

    .ackedNotificationIcon {
        z-index: 1;
        height: 24px;
        position: absolute;
        top: -16px;
        left: 4px;
        cursor: pointer;
        opacity: .2;
    }

    .titleContainer {
        display: flex;
        * {
            margin: 4px 0;
        }

        a {
            color: $anchor-color;
            cursor: pointer;
        }
    }

    .title {
        color: $highlight-1;
    }

    .docIcon {
        position: absolute;
    }

    .timestamp {
        margin-left: auto;
        font-size: 8px;
        color: $highlight-2;
    }

    .description {
        margin: 4px 0;
        line-height: 0.9rem;
    }
    .clear {
        color: $primary-color;
        background: #b55353;
        padding: 3px;
        position: absolute;
        right: -10px;
        top: -10px;
        height: 20px;
        width: 20px;
        svg {
            height: 12px;
            width: 12px;
        }
    }

    .sourceLinkContainer {
        text-align: left;

        a,
        a:visited,
        a:hover,
        a:active {
            color: $chat-anchor-color;
            text-decoration: none;
            font-size: clamp(0.6rem, 2vw, 0.8rem);
        }
    }
}
