@use 'helpers' as *;

.root {
    @include scrollbar-styles;

    .title {
        @include standard-font;
        display: flex;
        > h3 {
            margin: 4px auto 4px 0;
        }

        > svg {
            margin: auto 4px;
        }
    }
    :global(.MuiDialog-container) {
        :global(.MuiDialog-paper) {
            background: #1d1d1d;
            width: 100%;
            margin: 32px 12px;
        }
    }

    :global(.MuiTable-root) {
        background: $elev-02;
        @include standard-font;
        @include card-box-shadow;
        border: 1px solid #000;
        border-radius: 12px;

        :global(.MuiTable-cell) {
            padding: 0 16px;
            font-size: 12px;
        }

        @include scrollbar-styles;
        ::-webkit-scrollbar {
            max-height: 10px;
            height: 10px;
            max-width: 10px;
            width: 10px;
        }
        ::-webkit-scrollbar-button {
            display: none;
        }
    
    }

    :global(.MuiTableHead-root) {
        @include standard-font;
        font-size: 13px;
        color: $highlight-1;
        background: $elev-12;
        border-bottom: 1px solid $highlight-1;
        @include card-box-shadow;
        z-index: 9;
        border-radius: 12px 12px 0 0;

        :global(.MuiTableCell-root) {
            color: $highlight-1;
        }
    }

    :global(.MuiDialog-paperFullWidth) {
        width: calc(100% - 24px);
    }

    :global(.MuiBackdrop-root) {
        backdrop-filter: blur(2px);
    }

    :global(.MuiDialogContent-root) {
        padding: 20px 16px;
        background: #1d1d1d;
    }

    form {
        @include dialog-module-form;
        padding: 0;
        background: #1d1d1d;;
        width: 100%;
    }  

    .header {
        @include standard-font;
        padding: 20px 16px 8px;
        overflow: hidden;
        background-color: $elev-02;

        .openDocBtn {
            margin: 0px 20px;
        }

        h3 {
            margin: 6px 0;
        }

        > div {    
            display: flex;
        }

        > p {
            margin: 0;
            text-align: left;
            color: $secondary-color;
        }

        :global(.MuiChip-root) {
            color: $highlight-1;
            border: 1px solid $highlight-1;
            font-size: clamp(0.3rem, 2vw, 0.7rem);
            margin: auto 16px;
            height: 16px;
            @media (max-width: 800px) {
                margin: 2px;
            }
        }
    }

    .iconButtonContainer {
        display: flex;
        margin-right: auto;

        a {
            margin: auto 16px auto 4px;
        }
    }

    .documentActionsRoot,
    .documentAttachmentsRoot {
        > h5 {
            text-align: center;
            width: 100%;
            color: $highlight-1;
            position: relative;

            // Divider css
            @include surrounding-divider(150);
        }
    }

    .documentActionsRoot {
        > div {
            display: grid;
            grid-gap: 12px;
            justify-content: center;
            grid-template-columns: auto;
            @media (min-width: 500px) {
                grid-template-columns: auto auto auto;
            }

            .statusActionButton {
                @include standard-font;
                @include button-box-shadow;
                @include draw-meet-border-animation($highlight-1);
                color: $document-action-button-color;
                font-size: 12px;
                text-transform: unset;
                background: $document-action-button-background;
                padding: 8px 16px;
                //@include sm-device {
                width: 175px;
                //}
            }
        }
    }

    .sunEditorContainer {
        height: max-content;
    }

    .sectionDivider {
        @include standard-font;
        width: calc(100% + 32px);
        text-align: center;
        background: $section-divider-color;
        margin: 8px -16px;
        box-shadow: 0 0 3px -1px #000000;

        h5 {
            margin: 8px 0;
        }
    }

    .radioGroup {
        display: flex;
        margin: 12px 0;

        p {
            margin: auto 16px 4px 0;
            font-style: unset;
            color: $highlight-1;
            font-size: clamp(0.6rem, 2vw, 0.7rem);
        }

        :global(.MuiFormControlLabel-root) {
            margin: 0 8px;
        }

        :global(.MuiFormControlLabel-label) {
            @include standard-font;
            font-size: clamp(0.4rem, 2vw, 0.6rem);
            margin-bottom: 2px;
        }

        :global(.MuiRadio-root) {
            @include standard-font;
            padding: 0;

            :global(.MuiSvgIcon-root) {
                width: 1.2rem;
                height: 1.2rem;
            }
        }
    }

    .formGroup {
        margin: 12px 0;

        p {
            color: $highlight-1;
            font-size: clamp(0.6rem, 2vw, 0.7rem);
            margin: 0;
        }

        :global(.MuiFormGroup-root) {
            padding: 0 10px;
            gap: 16px;
            width: 100%;
        }

        :global(.MuiFormControlLabel-label) {
            @include standard-font;
            font-size: clamp(0.4rem, 2vw, 0.7rem);
        }

        :global(.MuiFormControlLabel-root) {
            margin-right: 12px;
        }

        :global(.MuiCheckbox-root) {
            @include standard-font;

            :global(.MuiSvgIcon-root) {
                width: 1.2rem;
                height: 1.2rem;
            }
        }
    }
}

.popper {
    @include card-box-shadow;
    background: $elev-12;
    padding: 12px 10px;
    display: flex;
    flex-direction: column;
    width: 450px;
    border-radius: 8px;
    transform: translate(-20px,-10px);
    position: relative;

    > h3 {
        color: $highlight-1;
        margin: 0px 6px 20px 6px;
    }

    .popperActions {
        display: flex;
        width: 100%;
        gap: 10px;
        justify-content: right;
    }

    .arrow {
        @include arrow-bottom($elev-12);
    }
}

.eventContainer {
    flex-grow: 1;
}

.actionsHeader {
   display: flex;
   flex-direction: row;
   justify-content: center;
   width: max-content;
}

.historyRoot {

}

.historyButtonDiv {
    position: absolute;
    top: -18px;
    right: 0px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 20px;
}

.historyButtonIcon {
    height: 18px;
    width: 18px;
    margin-right: 6px;
}

.historyContent {
    margin-top: -10px;
}

.historyTitle {
    color: $highlight-1;
}

.historyTable {
    max-height: 30vh;
}

