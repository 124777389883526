@use 'helpers' as *;

@mixin text-area {
    border: 1px solid #d0d0d0;
    width: 100%;
    text-align: left;
    padding: 8px;
    font-family: 'Gotham Book', Roboto, sans-serif;
    font-size: 12px;
}

.root {
    display: flex;
    flex-direction: column;
    background: #fff;
    color: $print-preview-font-color;
    padding: 24px;
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
    @media print {
        padding: 0;
    }

    :global(.MuiTypography-root),
    :global(.MuiFormLabel-root) {
        color: $print-correspondence-font-color;
    }

    .controls {
        display: flex;
        width: 100%;

        > div {
            margin: auto auto 16px;

            :global(.MuiButton-outlined) {
                margin-left: 16px;
                width: 125px;
            }
        }

        @media print {
            display: none;
        }
    }

    .paper {
        width: 1000px;
        margin: 0 auto;
        border: 1px solid grey;
        padding: 16px;
        display: flex;
        flex-direction: column;
        position: relative;
        @media print {
            padding: 0;
            border: none;
            #header{display:none;}
            #adwrapper{display:none;}
        }
    }

    .logo {
        width: 100px;
        position: absolute;
        left: 16px;
        top: 64px;
        @media print {
            left: 0;
            top: 64px;
        }
    }

    .header {
        font-size: 2rem;
        text-align: center;
    }

    .content {
        :global(.MuiInputLabel-formControl) {
            transform: translate(0px, 1.5px) scale(0.75);
            transform-origin: top left;
        }

        .row {
            color: $print-preview-font-color;
            display: flex;
            margin-bottom: 8px;

            div ~ div {
                margin-left: 16px;
            }
        }
    }

    .rowContent {
        display: flex;
        margin-bottom: 8px;
        margin-left: 64px;
        width: max-content; 
        margin-left: 30; 
        padding-top: 4;
    }

    .formBoxText {
        border: 1px solid black;
        background-color: #BDC581;
        white-space: nowrap;
        width: 150px;
        margin-left: 10px;
    }

    .dividerLabel {
        font-size: 12px;
        color: white;
        padding: 4px;
        margin-right: 20px !important;
        width: max-content;
        @media print {
            color: white !important;
        }
    }

    .headerLabel {
        color: white;
        @media print {
            color: white !important;
        }
    }

    .listLabel {
        color: grey;
        font-size: 14px;
    }

    .sectionCheckMarkContainer {
        display: flex;
        margin-bottom: 8px;
        margin-top: 6px;
        width: max-content;
        color: white;
        font-size: 14px;
        div ~ div {
            margin-left: 16px;
        }
        @media print {
            color: white !important;
        }
    }
    
    .sectionCheckMarkItem {
        width: 30px;
        border-bottom: solid 1px grey;
        margin-right: 10px;
        margin-top: 14px;
        position: relative;
        color: white;
        svg path {
            @media print {
                fill: white !important;
            }
        }
    }

    .checkMarkContainer {
        display: flex;
        margin-bottom: 8px;
        margin-top: 6px;
        width: max-content;
        div ~ div {
            margin-left: 16px;
        }
    }
    
    .checkMarkItem {
        width: 30px;
        border-bottom: solid 1px grey;
        margin-right: 10px;
        margin-top: 14px;
        position: relative;
    }

    .checkMarkIcon {
        position: absolute;
        top: -20px; 
        left: 6px;        
    }

    .sectionDivider {

        border: 1px solid #273c75;
        margin: 8px 0;
        background-color: #273c75;
        color: white;
        height: 30px;
        display: flex;

        @media print {
            background: #273c75 !important;
            border: 1px solid #273c75 !important;
            color: white !important;
        }

        :global(.MuiTypography-root) {
            margin: auto;
        }
    }

    .checkBoxGroup {
        display: flex;

        :global(.MuiIconButton-root) {
            padding: 4px 0;
        }

        label {
            margin: auto 16px auto 0;

            :global(.MuiCheckbox-root) {
                margin-right: 8px;
            }
        }

        .formControlLabel {
            margin: auto 20px auto 0;
        }
    }

    .formControlLabel {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.67);
        font-weight: 700;
        margin: auto auto auto 0;
        white-space: nowrap;
    }

    .textArea {
        min-height: 180px;
        @include text-area;
    }

    .textAreaCondensed {
        min-height: 125px;
        @include text-area;
    }

    .pcnTextArea {
        // min-height: 100px;
        border: 1px solid #d0d0d0;
        width: 100%;
        text-align: left;
        padding: 8px;
        font-family: 'Gotham Book', Roboto, sans-serif !important;
        font-size: 12px;
        min-height: 380px;
        max-height: 1200px;
    }

    .canvasContainer {
        width: 100%;
        border: 1px solid #b7b7b7;
        margin: 8px 0;
    }

    input[type='text'] {
        padding: 4px 0 3px;
        font-family: 'Gotham Book', Roboto, sans-serif !important;
        font-size: 12px;
        color: $print-preview-font-color;
    }

    .standardLine {
        width: 300px;
    }
}

.signatureContainer {
    overflow: visible;
    position: relative; 
    font-family: cursive;
    font-size: 18px;
    color: #273c75;
}

.signature {
    position: absolute; 
    top: -41px; 
    left: 0px;  
    width: 100%;
}

.signatureLabel {
    width: 300px; 
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-style: italic;
    font-size: 10px;
    margin-top: -12px;
}

.signatureRow {
    margin-left: 80px;
    margin-right: 80px;
    margin-bottom: 30px;
    display: flex; 
    justify-content: space-between;
}


