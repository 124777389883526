@use 'helpers' as *;
@use 'helpers' as *;

@mixin card-standard {
    display: flex;
    flex-direction: column;
    * {
        margin: 0;
        @include standard-font;
        color: inherit;
    }
}

.cardRoot {
    @include card-standard;
    line-height: 1.3rem;

    .title {
        text-align: center;
        margin-block-end: 8px;
        color: $highlight-1;

        a {
            color: $anchor-color;
        }
    }

    h3 {
        font-size: 0.9rem;
    }
}

.root {
    @include standard-font;
    width: 100%;
    overflow: auto;
    overflow-wrap: break-word;
    overflow-y: scroll;
    height: 170px;

    .scopeAttachmentList {
        @include standard-font;
        margin: 0;
        padding: 0;

        p {
            @include standard-font;
            margin-block-start: 0;
            margin-block-end: 6px;
        }
    }

    .scopeAttachmentListItem {
        @include card-box-shadow-shallow;
        background: $elev-12;
        padding: 12px 8px 12px 16px;
        display: flex;
        width: 95%;
        border-radius: 4px;
        margin-bottom: 8px;

        > div {
            overflow: hidden;
            white-space: nowrap;
            cursor: pointer;
            max-width: 175px;
            @include sm-device {
                max-width: 400px;
            }
            @include md-device {
                max-width: 600px;
            }
            > h4 {
                color: $anchor-color;
                margin: 0;
            }
            > p {
                text-overflow: ellipsis;
                font-size: clamp(0.5rem, 2vw, 0.7rem);
                overflow: hidden;
                color: #c6c6c6;
                margin: 8px 0 0;
            }
        }

        .fileName {
            margin-block-start: 4px;
            font-size: 0.8rem;
        }
    }
}
