@use 'helpers' as *;

.root {
    @include scrollbar-styles;

    //:global(.MuiDialog-paper) {
    //    background: transparent;
    //    margin: 32px 12px;
    //    border-radius: 0;
    //    display: flex;
    //    flex-direction: row;
    //    box-shadow: none;
    //    padding-bottom: 6px;
    //}

    :global(.MuiDialog-paperFullWidth) {
        width: calc(100% - 24px);
    }

    :global(.MuiBackdrop-root) {
        backdrop-filter: blur(2px);
    }

    :global(.MuiDialogTitle-root) {
        @include standard-font;
        padding: 20px 16px 8px;
        color: $highlight-1;

        > h4,
        > div {
            margin: 6px 0;
        }

        > p {
            margin: 0;
            text-align: left;
            color: $secondary-color;
        }

        :global(.MuiChip-root) {
            color: $highlight-1;
            border: 1px solid $highlight-1;
            font-size: clamp(0.3rem, 2vw, 0.7rem);
            margin: auto 16px;
            height: 16px;
        }
    }

    :global(.MuiDialogContent-root) {
        padding: 20px 16px;
    }

    form {
        @include dialog-module-form;
        padding: 0;
        background: $elev-04;
        width: 100%;
    }
}
