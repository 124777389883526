@use 'helpers' as *;

.root {
    @include scrollbar-styles;

    :global(.MuiDialog-container) {
        :global(.MuiDialog-paper) {
            background: transparent;
            margin: 32px 12px;
            border-radius: 0;
            display: flex;
            flex-direction: row;
            box-shadow: none;
            padding-bottom: 6px;
            overflow-y: auto;
        }
    }

    :global(.MuiDialog-paperFullWidth) {
        width: calc(100% - 24px);
    }

    :global(.MuiBackdrop-root) {
        backdrop-filter: blur(2px);
    }

    :global(.MuiTabPanel-root) {
        padding: 0;
        background: $elev-04;
        width: 100%;
        box-shadow: 4px 4px 3px -3px #000;
    }

    .header {
        @include standard-font;
        padding: 20px 16px 8px;

        .openDocBtn {
            margin: 0px 20px;
        }

        h3 {
            margin: 6px 0;
            color: $highlight-1;
        }

        > div {    
            display: flex;
        }

        > p {
            margin: 0;
            text-align: left;
            color: $secondary-color;
        }

        :global(.MuiChip-root) {
            color: $highlight-1;
            border: 1px solid $highlight-1;
            font-size: clamp(0.3rem, 2vw, 0.7rem);
            margin: auto 16px;
            height: 16px;
            @media (max-width: 800px) {
                margin: 2px;
            }
        }
    }

    :global(.MuiDialogContent-root) {
        padding: 20px 16px;
    }

    :global(.MuiTabs-root) {
        min-width: unset;
        padding: 0;
        overflow: visible;
        border-radius: 0 4px 4px 0;
        margin-bottom: 1px;
        align-items: center;
        height: fit-content;
        background-color: transparent;

        :global(.MuiTabs-flexContainer) {
            width: 48px;
            align-items: center;

            > button {
                margin-bottom: 1px;
                background-color: $elev-01;
                min-width: unset;
                width: 48px;

                &:global(.Mui-selected) {
                    background: $elev-04;

                    svg {
                        color: $highlight-1;
                    }
                }
            }
        }
    }

    // Document Information

    form {
        @include dialog-module-form;
        padding: 0;
        background: $elev-04;
        width: 100%;
    }

    .iconButtonContainer {
        display: flex;
        margin-right: auto;

        a {
            margin: auto 16px auto 4px;
        }
    }

    .infoContent {
        max-height: 70vh;

        @media (max-width: 800px) {
            max-height: 45vh;
        }
    }

    .documentActionsRoot,
    .documentAttachmentsRoot {
        > h5 {
            text-align: center;
            width: 100%;
            color: $highlight-1;
            position: relative;

            // Divider css
            @include surrounding-divider(150);
        }
    }

    .documentAttachmentsRoot {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .documentActionsRoot {
        > div {
            display: grid;
            grid-gap: 12px;
            justify-content: center;
            grid-template-columns: auto;
            @media (min-width: 500px) {
                grid-template-columns: auto auto auto;
            }

            .statusActionButton {
                @include standard-font;
                @include button-box-shadow;
                @include draw-meet-border-animation($highlight-1);
                color: $document-action-button-color;
                font-size: 12px;
                text-transform: unset;
                background: $document-action-button-background;
                padding: 8px 16px;
                //@include sm-device {
                width: 175px;
                //}
            }
        }
    }

    // Document History
    .documentHistoryContent {
        padding: 8px 16px 20px;
        height: 500px;
        display: flex;
        flex-direction: column;
        @include standard-font;
    }

    .timestamp {
        font-size: 0.5rem;
        color: $highlight-1;
    }

    .historySearch {
        margin-bottom: 16px;

        :global(.MuiInputBase-root),
        :global(.MuiFormLabel-root) {
            @include standard-font;
            font-size: 12px;
            color: $highlight-1;
        }

        :global(.MuiInput-underline) {
            &:before,
            &:after {
                border-bottom: 1px solid $form-control-underline;
            }
        }

        :global(.MuiInputBase-input) {
            font-size: 12px;

            color: $primary-color;
        }
    }

    // Document comments
    .commentContent {
        padding: 20px 16px;
        max-height: 50vh;
        overflow-x: hidden;
    }

    .commentActions {
        flex-direction: column;
        padding: 8px 16px;
        background: $elev-08;
        border-radius: 0 18px 0 0;
        box-shadow: 0 -3px 3px -1px #000;
    }
}

.popper {
    @include card-box-shadow;
    background: $elev-12;
    padding: 12px 18px;
    display: flex;
    flex-direction: column;
    width: 350px;
    border-radius: 8px;
    transform: translateY(-12px);
    position: relative;

    > h3 {
        color: $highlight-1;
    }

    .popperActions {
        display: flex;
        width: 100%;
        gap: 10px;
        justify-content: right;
    }

    .arrow {
        @include arrow-bottom($elev-12);
    }
}

.eventContainer {
    flex-grow: 1;
}
