@use 'helpers' as *;

.root {
    padding: 32px;

    @include md-device {
        width: 35%;
        margin: auto;
    }

    form {
        @mixin underline {
            border-bottom: 1px solid $highlight-1;
        }
        @include standard-font;
        padding: 0;
        width: 100%;

        :global(.MuiInput-underline) {
            &:global(.Mui-disabled) {
                &:before,
                &:after {
                    border-bottom: none;
                }
            }

            &:before,
            &:after {
                border-bottom: 1px solid $form-control-underline;
            }

            &:hover {
                &:not(.Mui-disabled) {
                    &:before {
                        @include underline;
                        transition: color 0.3s ease-in-out;
                    }
                }
            }
        }

        :global(.MuiInputBase-input) {
            @include standard-font;
            font-size: 0.7rem;
        }

        :global(.MuiInputBase-root) {
            &:global(.Mui-disabled) {
                :global(.MuiAutocomplete-endAdornment) {
                    display: none;
                }
            }

            * {
                color: $primary-color;
            }

            &:global(.MuiInputBase-multiline) {
                padding: 6px 0 0;
            }
        }

        :global(.MuiFormControl-root) {
            margin-bottom: 12px;
        }

        :global(.MuiFormLabel-root) {
            @include standard-font;
            color: $highlight-1;
            font-size: 0.9rem;
        }

        p {
            font-size: 0.6rem;
            font-style: italic;
            color: $highlight-1;
            text-align: center;
        }
    }
}

.title {
    @include standard-font;
    font-size: 1.5rem;
}

.header {
    @include standard-font;
    font-size: clamp(1em, 2vw, 1.2em);
    color: $highlight-1;
}

.subheader {
    @include standard-font;
    font-size: clamp(0.75em, 2vw, 0.8em);
    margin: 12px 0;
    color: $primary-color;
}

.required {
    @include standard-font;
    font-size: clamp(0.6em, 2vw, 0.8em);
    margin: 12px 0;
    color: $secondary-color;
}

.thankYou {
    margin-top: 50px;
    :global(.MuiTypography-root) {
        @include standard-font;
    }

    :global(.MuiTypography-h4) {
        font-size: 1.2rem;
        margin-bottom: 16px;
    }

    :global(.MuiTypography-subtitle1) {
        font-size: 0.8rem;
    }
}

.submit {
    text-align: right;
}
