@use 'helpers' as *;

.root {
    display: flex;
    flex-direction: column;
    height: 268px;
}

.title {
    @include standard-font;
    margin: 0 auto;
    cursor: pointer;
    a {
        color: $anchor-color;
    }
}

.projectLabel {
    color: $highlight-1;
    margin-block-start: 4px;
    margin-block-end: 4px;
    text-align: center;
    position: relative;
    @include surrounding-divider(120);
}

.subheader {
    @include standard-font;
    margin-block-start: 8px;
    margin-block-end: 0;
    color: $highlight-1;
}

.description {
    line-height: 1rem;
    height: 138px;
    overflow: auto;
    img {
        object-fit: contain;
        max-width: 100%;
    }
}

.openDuration {
    color: $highlight-1;
}

.flexContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > div {
        width: 50%;
    }
}
.assignments {
    > div {
        margin-bottom: 8px;
    }
    .subheader {
        margin-block-end: 4px;
    }
}

.assignedChip {
    &:global(.MuiChip-root) {
        color: #000;
    }
    margin-top: 4px;
    margin-right: 4px;
    height: 20px;
    font-size: clamp(0.6rem, 2vw, 0.85rem);
    max-width: 150px;
}
