@use 'helpers' as *;

.nav {
    $padding-top: 72px;
    position: relative;
    overflow: visible;
    display: flex;
    > :global(.MuiButton-root) {
        min-width: unset;
    }

    ul {
        padding-left: 0;
        padding-top: $padding-top;
        margin: 0;
        @include opaque-glass;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: absolute;
        top: -16px;
        right: -16px;
        width: 100vw;
        height: calc(100vh - 64px);
        overflow-y: auto;

        @include md-device {
            width: 30vw;
        }

        @media (min-width: 1590px) {
            width: 20vw;
        }

        @media (min-width: 1660px) {
            right: -109px;
        }

        li {
            padding: 0 32px;
            display: flex;
            cursor: pointer;
            direction: rtl;
            overflow-y: visible;

            a {
                text-decoration: none;
            }

            h2 {
                @include bold-header;
                text-align: right;
                position: relative;
                display: block;

                &::after {
                    margin-top: 8px;
                    position: absolute;
                    content: '';
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: $highlight-1;
                    transform: scaleX(0);
                    transform-origin: right;
                    transition: transform 0.75s;
                }
            }

            &:hover {
                h2 {
                    color: $highlight-1;

                    &::after {
                        transform: scaleX(1);
                        transform-origin: left;
                    }
                }
            }
        }
    }

    .collapseWrapper {
        // Required otherwise collapsible will not take up height and overlap each other but MuiCollapse sets this at the element level
        min-height: unset !important;
    }
}

.optionsCollapsible {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    background: $elev-04;

    a {
        @include caption;
        flex: 1 1 auto;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        height: 65px;
        width: 100%;
        font-size: 0.9rem;
        text-decoration: none;
        line-height: 1.3rem;
        text-align: center;
        vertical-align: middle;

        span {
            max-width: 100px;
            margin: auto;
        }

        &:hover {
            color: $highlight-1;
        }
    }
}

.comingSoonFeature {
    position: relative;

    & span::before {
        content: '';
        position: absolute;
        background-image: url('~/assets/img/coming-soon-logo.svg');
        background-size: contain;
        background-size: 50px 50px;
        background-repeat: no-repeat;
        width: 50px;
        height: 50px;
        transform: translate(-50%, -55%);
    }
}
