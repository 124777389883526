@use 'helpers' as *;

.root {
    :global(.MuiDialog-paper) {
        background: $elev-04;
        width: 100%;
        margin: 32px 12px;
    }

    :global(.MuiDialog-paperFullWidth) {
        width: calc(100% - 24px);
    }

    :global(.MuiBackdrop-root) {
        backdrop-filter: blur(2px);
    }

    :global(.MuiDialogTitle-root) {
        @include standard-font;
        padding: 20px 16px 8px;
        color: $highlight-1;
        text-align: center;

        > h4,
        > div {
            margin: 6px 0;
            font-size: 1.2rem;
            letter-spacing: 2px;
        }
    }

    :global(.MuiDialogContent-root) {
        padding: 20px 16px;
    }

    .card {
        background: $elev-02;
        padding: 8px;
        @include card-box-shadow-shallow;
    }

    :global(.katex) {
        font-size: clamp(0.58rem, 2.25vw, 0.8rem);
    }

    :global(.MuiAccordion-root) {
        background: $elev-08;

        &:global(.Mui-expanded) {
            margin: 0;
            border-bottom: 1px solid #1f1f1f;
        }
    }

    :global(.MuiAccordionSummary-root) {
        @include standard-font;
        color: $highlight-1;
        font-size: clamp(0.7rem, 2vw, 0.8rem);

        &:global(.Mui-expanded) {
            @include card-box-shadow-shallow;
        }

        :global(.MuiIconButton-root) {
            color: $highlight-2;

            svg {
                height: 1rem;
                width: 1rem;
            }
        }

        .summaryTitle {
            margin-right: auto;
        }
    }

    :global(.MuiAccordionDetails-root) {
        @include standard-font;
        background: $underlay-bg-color;
        flex-direction: column;

        ul {
            padding-inline-start: 25px;
        }
    }

    .content {
        display: flex;
        @include standard-font;
        color: $primary-color;

        > div {
            margin: auto;
            text-align: center;

            > p {
                font-size: clamp(0.8rem, 2vw, 1rem);
                margin: 8px auto;
            }
        }

        :global(.MuiDivider-root) {
            background: $vertical-divider;
        }


        svg {
            width: 24px;
            height: 24px;
        }
    }
}
