@use 'helpers' as *;

.dialogRoot {
    @include standard-font;

    :global(.MuiDialogTitle-root) {
        @include standard-font;

        color: $highlight-1;
    }

    :global(.MuiDialog-paper) {
        padding: 24px 16px 16px;
        background: $elev-02;
    }

    :global(.MuiDialogContent-root) {
        font-size: 13px;
        line-height: 1.3rem;
        display: flex;
        flex-direction: column;
    }

    .emailField {
        margin: 24px auto 8px;
        width: 80%;
    }
}

.clickable {
    color: $highlight-1;
    cursor: pointer;
}
