@use 'helpers' as *;

.root {
    margin: 16px auto;

    @include md-device {
        padding: 0 30px;
    }

    @include xl-device {
        max-width: 50%;
        padding: 0 20%;
    }

    .healthReportCardTitle {
        @include bold-header;
        text-align: center;
        margin: 12px 0;
    }

    .card {
        background: $elev-02;
        padding: 8px;
        @include card-box-shadow-shallow;
        @include sm-device {
            padding: 32px;
        }
    }

    .actualHours {
        @include standard-font;
        font-size: clamp(2rem, 2vw, 2.5rem);
        color: $highlight-2;
    }

    :global(.katex) {
        font-size: clamp(0.58rem, 2.25vw, 0.8rem);
    }

    .scoreRoot {
        margin: 16px 0;

        .accordionContainer {
            @include card-box-shadow-shallow;
        }

        :global(.MuiAccordion-root) {
            background: $elev-08;

            &:global(.Mui-expanded) {
                margin: 0;
                border-bottom: 1px solid #1f1f1f;
            }
        }

        :global(.MuiAccordionSummary-root) {
            @include standard-font;
            color: $highlight-1;
            font-size: clamp(0.7rem, 2vw, 0.8rem);
            padding: 8px;
            width: 100%;

            &:global(.Mui-expanded) {
                @include card-box-shadow-shallow;
            }

            :global(.MuiIconButton-root) {
                color: $highlight-2;
                svg {
                    height: 1rem;
                    width: 1rem;
                }
            }
            :global(.MuiAccordionSummary-expandIconWrapper) {
                margin-left: 4px;
            }

            .summaryTitle {
                margin-right: auto;
            }
        }

        :global(.MuiAccordionDetails-root) {
            @include standard-font;
            background: $underlay-bg-color;
            flex-direction: column;

            ul {
                padding-inline-start: 25px;
            }
        }

        .calculationContainer {
            text-align: center;
            padding: 8px 0;
        }

        .resultContainer {
            display: flex;

            > div {
                margin: auto;
                text-align: center;

                > p {
                    font-size: clamp(0.8rem, 2vw, 1rem);
                    margin: 8px auto;
                }
            }

            :global(.MuiDivider-root) {
                background: $vertical-divider;
            }

            .score {
                font-size: 1rem;

                @include xl-device {
                    font-size: clamp(1rem, 2vw, 1.5rem);
                }
            }

            svg {
                width: 24px;
                height: 24px;
            }
        }
    }
}
