@use 'helpers' as *;

.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    a {
        color: $anchor-color;
    }

    :global(.MuiTypography-root) {
        @include standard-font;
        margin: 8px auto 4px;
        text-align: center;
    }
}

.contactContainer {
    text-align: center;
    :global(.MuiTypography-subtitle1) {
        font-size: 18px;
        color: $highlight-1;
    }

    :global(.MuiTypography-subtitle2) {
        font-size: 11px;
    }
}
