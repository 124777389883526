@use 'helpers' as *;

.root {
    display: flex;
    position: sticky;
    position: -webkit-sticky;
    padding-right: 0px !important;
    top: 0;

    &:global(.MuiAppBar-root) {
        background-color: #040404;
        box-shadow: 0 3px 7px 3px #000;
        left: 0;
        width: 100vw;
        @include standard-font;
    }

    :global(.MuiToolbar-regular) {
        min-height: 0;
        height: 50px;
    }

    :global(.MuiToolbar-gutters) {
        padding-left: 12px;
        padding-right: 12px;
    }

    @media (max-width: 675px) {
        :global(.MuiToolbar-gutters) {
            padding-left: 2px;
            padding-right: 6px;
        }
    }

}

.logo {
    height: 32px;
    width: auto;
    margin: auto 16px auto 8px;
    cursor: pointer;
}

.inactiveLogo {
    height: 32px;
    width: auto;
    margin: auto 16px;
}

.optionsContainer {
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    width: 100vw;
}

.dashboardOptionsContainer {
    display: flex;
    width: 100%;
    justify-content: right;
    -webkit-justify-content: flex-end;
}

.vertIcon {
    position: fixed;
    right: 60;
    @media (max-width: 675px) {
        position: relative;
        right: auto;
    }
}

.projectsMenuContainer {
    display: flex; 
    align-items: center;
    width: min-content;
    @media (max-width: 675px) {
        justify-content: flex-start;
    }
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.menuOptions {
    justify-content: space-evenly;
    display: flex;
    height: 48px;
}

.menuFolderTab {
    font-size: 12px;
    color: $primary-color;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 50px;
    display: flex;
    padding: 0 8px;
    margin-top: 10px;
    border-top: 18px solid transparent;
    border-left: 1px solid #2f3640;
    border-right: 1px solid #2f3640;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 20px solid transparent;
    text-decoration: none;

    &:hover {
        color: $highlight-1;
        transition: color 0.3s ease-in-out;
        background-color: #121212;
    }

    &.currentPage {
        color: $highlight-1;
        background-color: #121212;
        border: 1px solid #2f3640;
        border-bottom: 1px solid transparent;
        padding-bottom: 0px;
        margin-bottom: -1px;
    }
    &.largeScreen {
        min-width: 90px;
    }
}

.menuButton {
    font-size: 12px;
    color: $primary-color;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    padding: 0 8px;
    border-bottom: none;
    border-radius: 16px 16px 0px 0px;
    text-decoration: none;

    &:hover {
        color: $highlight-1;
        transition: color 0.3s ease-in-out;
    }

    &.currentPage {
        color: $highlight-1;
        border-bottom: none;
    }
    &.largeScreen {
        padding: 0 18px;
    }
}

.cppLabel {
    font-size: 0.8rem;
    display: none;
    @include sm-device {
        display: inline;
    }
}

.subsiteLabel {
    max-width: 215px;
    p {
        margin-block-start: 0;
        margin-block-end: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 9px;
        line-height: 1rem;
    }

    .title {
        font-size: 0.75rem;
        color: $highlight-1;
    }

    div {
        justify-content: flex-start;
        padding: 0px 0px 0px 2px;
    }

    a {
        text-decoration: none;
    }
}

.listOptions {
    .listItemButton {
        margin: 0px;
        padding: 0px 4px;
        width: 99%;
    }

    .listItemText {
        padding: 0px 2px;
        margin: 4px 0px;
    }
        
    p {
        margin-block-start: 0;
        margin-block-end: 0;
        text-overflow: ellipsis;
        line-height: 1rem;
        
    }

    .optionTitle {
        font-size: 0.70rem;
        color: $highlight-1;
    }

    .option {
        font-size: 15px;
        color: #fff;
    }

    div {
        justify-content: flex-start;
        padding: 0px 4px;
    }

    a {
        text-decoration: none;
        padding: 0px;
        margin: 0px;
    }
}

.projectsListContainer {
    max-height: 80vh;
    padding: 2px -2px 0px 0px;
    width: 100%;

    .mainList {
        max-height: 60vh;
        overflow: auto;
        border: none;
        
        .noMatch {
            margin-top: 20px;
            font-size: 18px;
            color:rgb(243, 189, 117)
        }
    }

    button {
        color: rgb(237, 223, 223);
        font-size: 0.6rem;
        font-weight: 100;
        padding: 2px 6px;
        z-index: 30;
    }
    
}

.topDivider {
    display: flex;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
    .startDivider {
        height: 1vh;
        width: 30px;
        min-width: 30px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }
    .endDivider {
        height: 1vh;
        width: 100%;
        min-width: 30px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }
    .searchRow {
        display: flex;
    }
    .searchContainer {
        margin-top: -6px;
        min-width: 240px;
        width: 240px;
        border-color: $highlight-1 !important;
    }
    .searchBar {
        width: 240px;
        padding: 4px 4px 4px 12px;
    }
    .searchSpan {
        opacity: 0;
        margin: 0px;
        font-size: 1px;
    }
}

.filterIcon {
    cursor: pointer;
    width: 40px;
    min-width: 40px;
    margin-left: 5px;
    margin-right: 0px;
    margin-top: 2px;
}

.settingsIcon {
    padding-top: 14px;
    padding-bottom: 10px;
    color: rgb(167, 158, 158);
    font-size: 14px;
}









