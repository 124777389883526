@use 'helpers' as *;

@mixin text-area {
    border: 1px solid #d0d0d0;
    text-align: left;
    padding: 8px;
}

.root {
    padding: 24px;
    background: #fff;
    color: $print-preview-font-color;
    display: flex;
    flex-direction: column;
    @media print {
        padding: 0;
    }

    :global(.MuiTypography-root),
    :global(.MuiFormLabel-root) {
        @include standard-font;
        color: $print-correspondence-font-color;
    }

    .controls {
        display: flex;
        width: 100%;

        > div {
            margin: auto auto 16px;

            :global(.MuiButton-outlined) {
                margin-left: 16px;
                width: 125px;
            }
        }

        @media print {
            display: none;
        }
    }

    .paper {
        width: 1000px;
        margin: 0 auto;
        border: 1px solid grey;
        padding: 16px;
        display: flex;
        flex-direction: column;
        position: relative;
        @media print {
            padding: 0;
            border: none;
        }
    }

    .logo {
        width: 100px;
    }

    .header {
        display: flex;
        margin-bottom: 32px;

        :global(.MuiTypography-root) {
            font-size: 1.75rem;
            text-align: center;
            margin: 0 auto;
        }
    }

    .content {
        :global(.MuiInputLabel-formControl) {
            transform: translate(0px, 1.5px) scale(0.75);
            transform-origin: top left;
            @media print {
                color: $print-preview-font-color;
            }
        }

        .row {
            display: flex;
            margin-bottom: 8px;

            div ~ div {
                margin-left: 16px;
            }
        }
    }

    .sectionDivider {
        @media print {
            background-color: #d5dce0 !important;
        }
        border: 1px solid #d5dce0;
        margin: 8px 0;
        background-color: #d5dce0;
        height: 30px;
        display: flex;

        :global(.MuiTypography-root) {
            margin: auto;
        }
    }

    .checkBoxGroup {
        display: flex;

        :global(.MuiIconButton-root) {
            padding: 4px 0;
        }

        :global(.MuiTypography-root) {
            font-size: 12px;
        }

        :global(.MuiCheckbox-colorSecondary.Mui-checked) {
            color: $print-checkbox-color;
        }

        label {
            :global(.MuiCheckbox-root) {
                font-size: 12px;
            }
        }

        .formControlLabel {
            margin: auto 20px auto 0;
        }
    }

    .formControlLabel {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.54);
        margin: auto auto auto 0;
        @media print {
            color: $print-preview-font-color;
        }
    }

    .textArea {
        @include text-area;
        min-height: 180px;
    }

    .textAreaCondensed {
        min-height: 100px;
        @include text-area;
    }

    .rfiTextArea {
        border: 1px solid #d0d0d0;
        text-align: left;
        padding: 8px;
        font-size: 12px;
        min-height: 24px;
    }

    .canvasContainer {
        border: 1px solid #b7b7b7;
        margin: 8px 0;
    }

    input[type='text'] {
        padding: 4px 0 3px;
        font-family: 'Gotham Book', Roboto, sans-serif !important;
        font-size: 12px;
        color: $print-preview-font-color;
    }

    .MuiSvgIcon-root {
        color: gray !important;
    }
}
