@use 'helpers' as *;

@mixin export-button-style {
    @include standard-font-family;
    color: $add-button-color;
    font-size: clamp(0.5rem, 2vw, 0.7rem);
    letter-spacing: 1px;
    position: relative;
    @include md-device {
        margin-left: 5px;
    }
}


.tableRoot {
    width: 100%;
    padding-bottom: 40px;
    
    &.nodata {
        height: 260px;
    }

    &.bigdata {
        height: 420px;
    }

    .chip {
        &:global(.MuiChip-root) {
            @include standard-font;
            font-size: 10px;
        }
    
        @include card-box-shadow-shallow;
    }
}

.root {
    height: 100%;
    padding: 0 32px 48px;
    display: flex;
    flex-direction: column;

    @include md-device {
        padding: 0 48px 48px;
    }

    .header {
        @include bold-header;
        text-align: center;
        margin: 32px auto 8px;
        @include sm-device {
            margin: 32px auto 16px;
        }
    }

    .card {
        position: relative;
        min-height: 150px;
        background: $elev-02;
        padding: 16px;
        border-radius: 4px;
        @include card-box-shadow-shallow;

        @include md-device {
            min-height: 300px;
        }

        @include lg-device {
            min-height: 400px;
        }
    }

    .topRoundedOnly {
        border-radius: 4px 4px 0 0;
    }

    .kpiContainer {
        margin-top: 16px;
    }
    :global(.MuiPaper-root) {
        background-color: transparent;
    }
}

.row {
    cursor: pointer;

    &.readOnly {
        color: rgb(194, 193, 193);
    }

    &.treeBranch {
        background: #161618;
        font-style:oblique;
        color: rgb(207, 204, 204) !important;
        font-weight: 100 !important;
    }

    &.actionRequired {
        color:  #90caf9;
        font-style: o;
        font-weight: 600; 
    }

    &.actionable {
        color:  #FFF;
    }

    &.editable {
        color:  #FFF;
    }

    &.actionableEditable {
        color:  #FFF;
    }
}

.progressChartContainer {
    width: 90vw;
    @include md-device {
        max-width: 1820px;
        margin: 0 auto;
    }
}
