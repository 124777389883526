@use 'helpers' as *;

.root {
    padding-bottom: 0;
}

.paddingBottom {
    // To make room for chat input which is position absolute
    padding-bottom: 72px;
}

.selectedPaddingBottom {
    // To make room for chat input and selected comment data which is position absolute
    padding-bottom: 164px;
}

.dateSection {
    > p {
        @include standard-font;
        margin: 12px auto;
        padding: 0 8px;
        background: $elev-01;
        width: fit-content;
        border-radius: 12px;
        font-size: 0.5rem;
    }
}
