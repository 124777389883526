@use 'helpers' as *;

.root {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .header {
        @include standard-font;
        font-size: 1.5rem;
    }

    @include sm-device {
        padding: 32px 64px 64px;
    }
}

.userInformationSection {
    display: flex;
    flex-direction: column;
    gap: 16px;

    :global(.MuiFormLabel-root) {
        color: $highlight-1;
    }

    @include sm-device {
        width: 400px;
    }
}
