@use 'helpers' as *;

.serviceContent {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
}
