@use 'helpers' as *;

.root {
    padding: 16px;
    background: $elev-02;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    @include card-box-shadow;

    @include xl-device {
        max-width: 1500px;
    }

    @media (max-width: 1710px) {
        max-width: 900px;
    }

    @media (max-width: 995px) {
        max-width: 650px;
    }

    @media (max-width: 730px) {
        max-width: 420px;
    }

    @include sm-device {
        padding: 24px;
        min-width: 420px;

        flex-grow: 0;
    }

    min-height: 194px;
    min-width: unset;
    flex-grow: 1;

    .titleContainer {
        position: relative;
        width: 100%;

        h2 {
            @include bold-header;
            display: flex;
            flex-direction: row;
            justify-content: center;
            @include tapered-surrounding-divider(150);

            .title {
                position: relative;
                width: max-content;

                .filterIndicator {
                    position: absolute;
                    z-index: 1;
                    top: -24px;
                    left: 90%;
                    cursor: pointer;
                }
            }
        } 
    }
}

.columnContainer {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 16px;
    @include sm-device {
        justify-content: center;
    }
}

.toDoColumnContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    flex-grow: 1;

    @include sm-device {
        flex-direction: column;
        flex-grow: 0;
        width: 200px;
    }

    :global(.MuiAccordion-root) {
        width: 100%;
        border: 2px inset rgba(152, 152, 152, 0.6);
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        display: inline-block;
        @include card-box-shadow;

        &:last-of-type {
            border-radius: 8px;
        }

        &:global(.Mui-expanded) {
            margin: 0;
            border: 2px inset rgba(255, 213, 96, 0.6);
            border-radius: 8px;
        }

        :global(.MuiAccordionSummary-root.Mui-expanded) {
            min-height: 48px;
            background-color: #2a2a2a;
            border-radius: 8px 8px 0 0;
        }

        &:hover {
            border: 2px inset rgba(255, 213, 96, 0.6);
            transition-property: border-color;
            transition-duration: 0.3s;
            transition-timing-function: ease-in-out;
        }
    }

    :global(.MuiAccordionSummary-content) {
        @include standard-font-family;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 13px;
        height: 30px;
        cursor: pointer;
        margin: 0;

        &:global(.Mui-expanded) {
            margin: 0;
            color: $highlight-2;
        }

        &:hover {
            color: $highlight-1;
            transition: color 0.3s ease-in-out;
        }
    }

    :global(.MuiAccordionDetails-root) {
        padding: 8px;
        border-radius: 0 0 12px 12px;
    }
}

.columnSubtotal {
    width: 75px;
    font-size: 40px;
    padding: 8px;
    text-align: center;
    @include sm-device {
        font-size: 50px;
    }
}

.detailTable {
    width: 100%;

    th {
        padding: 4px 8px;
        font-size: 13px;
        font-weight: normal;
        color: $highlight-1;
    }

    td {
        font-size: 12px;
        padding: 5px 8px;

        a {
            color: $anchor-color;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.imageContainer {
    img {
        width: 225px;
    }
}
