@use 'helpers' as *;

.root {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.header {
    @include bold-header;
    text-align: center;
}
