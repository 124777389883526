@use 'helpers' as *;

@mixin card-standard {
    display: flex;
    flex-direction: column;
    * {
        margin: 0;
        @include standard-font;
        color: inherit;
    }
}

.cardRoot {
    @include card-standard;
    line-height: 1.3rem;

    .completedContainer {
        position: absolute;
        bottom: 50px;
        width: calc(100% - 36px);
        text-align: center;

        .completedIndicator {
            margin: 0 -16px;
            height: 20px;
            position: relative;

            p {
                font-weight: bold;
                font-size: 1.1rem;
                color: #212121;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                line-height: 1rem;
                margin: 0;
            }
        }
    }

    .title {
        text-align: center;
        margin-block-end: 8px;
        color: $highlight-1;

        a {
            color: $anchor-color;
        }
    }

    h3 {
        font-size: 0.9rem;
    }
}
