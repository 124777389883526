@use 'helpers' as *;

.root {
    @include card-box-shadow-shallow;
    background: $elev-12;
    padding: 12px 8px 12px 16px;
    display: flex;
    width: fit-content;
    border-radius: 4px;
    margin-bottom: 8px;

    > div {
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        max-width: 175px;
        @include sm-device {
            max-width: 400px;
        }
        @include md-device {
            max-width: 600px;
        }
        > h4 {
            color: $anchor-color;
            margin: 0;
        }
        > p {
            text-overflow: ellipsis;
            font-size: clamp(0.5rem, 2vw, 0.7rem);
            overflow: hidden;
            color: #c6c6c6;
            margin: 8px 0 0;
        }
    }
}
