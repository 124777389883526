@use 'helpers' as *;

.container {
    height: 100%;
    width: 100%;
    // overflow-wrap: anywhere;
    position: relative;

    .title {
        top: 20px;
        transform: translate(-50%, -8px);
        max-width: 130px;
    }

    .count {
        top: 55%;
        transform: translate(-50%, -50%);
    }

    .icon {
        top: 55%;
        transform: translate(-50%, -50%);
        width: 2.5rem;
        height: 2.5rem;
    }

    .exploreIcon {
        z-index: 1;
        opacity: .6;
        color: $highlight-1;
        position: absolute;
        top: -4%;
        right: -4%;
        font-size: 18px;
    }
}

.hidden {
    display: none;
}

.splitContainer {
    .topTitle {
        top: 20px;
        transform: translate(-50%, -8px);
    }

    .bottomTitle {
        top: 50%;
        transform: translateX(-50%);
    }

    .top,
    .bottom {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .top {
        top: 25%;

        &::after {
            font-size: 1rem;
            content: '%';
        }
    }

    .bottom {
        top: 67%;

        &::after {
            font-size: 1rem;
            content: '%';
        }
    }

    .over {
        color: $positive;
    }

    .under {
        color: $negative;
    }
}

.title {
    font-size: 10px;
    position: absolute;
    left: 50%;
    color: $highlight-1;
    overflow: hidden;
    white-space: normal;
    word-wrap: normal;

    a {
        color: $anchor-color;
    }

    @include sm-device {
        font-size: 0.7rem;
    }
}

.count {
    font-size: 3.5rem;
    position: absolute;
    left: 50%;
}

.icon {
    position: absolute;
    left: 50%;
}
