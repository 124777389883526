@use 'helpers' as *;

.root {
    @include scrollbar-styles;

    .title {
        @include standard-font;
        display: flex;

        > h3 {
            margin: 4px auto 4px 0;
        }

        > svg {
            margin: auto 4px;
        }
    }

    :global(.MuiDialog-paper) {
        background: $elev-04;
        width: 100%;
        margin: 32px 12px;
    }

    :global(.MuiDialogTitle-root) + :global(.MuiDialogContent-root) {
        padding-top: 24px;
    }

    :global(.MuiDialogContent-root) {
        background: $elev-01;
        padding: 24px;
        overflow-x: hidden;
    }

    form {
        @include dialog-module-form;

        :global(.MuiTextField-root) {
            width: 100%;
        }
    }

    .sunEditorContainer {
        height: max-content;
    }

    .sectionDivider {
        @include standard-font;
        width: calc(100% + 32px);
        text-align: center;
        background: $section-divider-color;
        margin: 8px -16px;
        box-shadow: 0 0 3px -1px #000000;

        h5 {
            margin: 8px 0;
        }
    }

    .radioGroup {
        display: flex;
        margin: 12px 0;

        p {
            margin: auto 16px 4px 0;
            font-style: unset;
            color: $highlight-1;
            font-size: clamp(0.6rem, 2vw, 0.7rem);
        }

        :global(.MuiFormControlLabel-root) {
            margin: 0 8px;
        }

        :global(.MuiFormControlLabel-label) {
            @include standard-font;
            font-size: clamp(0.4rem, 2vw, 0.6rem);
            margin-bottom: 2px;
        }

        :global(.MuiRadio-root) {
            @include standard-font;
            padding: 0;

            :global(.MuiSvgIcon-root) {
                width: 1.2rem;
                height: 1.2rem;
            }
        }
    }

    .formGroup {
        margin: 12px 0;

        p {
            color: $highlight-1;
            font-size: clamp(0.6rem, 2vw, 0.7rem);
            margin: 0;
        }

        :global(.MuiFormGroup-root) {
            padding: 0 10px;
            gap: 16px;
            width: 100%;
        }

        :global(.MuiFormControlLabel-label) {
            @include standard-font;
            font-size: clamp(0.4rem, 2vw, 0.7rem);
        }

        :global(.MuiFormControlLabel-root) {
            margin-right: 12px;
        }

        :global(.MuiCheckbox-root) {
            @include standard-font;

            :global(.MuiSvgIcon-root) {
                width: 1.2rem;
                height: 1.2rem;
            }
        }
    }
}
