@use 'helpers' as *;

.root {
    width: 100%;

    @include sm-device {
        margin-top: 16px;
    }

    @include md-device {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 16px;
    }

    .header {
        @include bold-header;
        margin: 10px auto 10px;
        height: 30px;

        .button {
            padding-left: 10px;
        }
    }
}

.teamTableContainer {
    display: flex;
    flex-direction: column;
    @include md-device {
        width: 48%;
    }
}
