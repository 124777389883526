@use 'helpers' as *;

.root {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
}

.title {
    @include standard-font;
    margin-block-start: 0;
    margin-block-end: 16px;
    text-align: center;
    font-size: 0.9rem;
    color: $highlight-1;

    a {
        color: $anchor-color;
    }
}

.projectScopeContainer {
    height: 100%;
    .projectScope {
        max-height: 175px;
        overflow: auto;
        overflow-wrap: break-word;
        overflow-y: scroll;
        a {
            color: $save-button-color;
        }

        a:visited {
            color: $secondary-color;
        }
    }
}
