@use 'helpers' as *;

.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    text-align: center;
    padding: 12px;

    h2 {
        @include bold-header;
        font-size: 28px;
        @include sm-device {
            font-size: clamp(28px, 2.5rem, 42px);
        }
    }

    p {
        font-size: 14px;
    }

    a {
        color: $anchor-color;
    }

    .url {
        color: $highlight-1;
    }

    .homeLink {
        padding-top: 30px;
    }
}

.logoNavBar {
    display: flex;
    justify-content: space-between;
}
