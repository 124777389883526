@use 'helpers' as *;

@mixin card-standard-style {
    display: flex;
    flex-direction: column;
}

.title {
    @include standard-font;
    margin: 0;
    font-size: 0.9rem;
    color: $highlight-1;
}

.donutChartCard {
    @include card-standard-style;
    .title {
        margin: 0 auto 4px;
    }
}
