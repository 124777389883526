@use 'helpers' as *;

.root {
    @include standard-font;
    @include card-box-shadow-shallow;
    border-radius: 4px;

    &:global(.MuiAccordion-root) {
        margin: 32px auto;
        background: $elev-02;

        @include lg-device {
            max-width: 75%;
        }

        @include xl-device {
            max-width: 50%;
        }
    }

    :global(.MuiAccordionSummary-root) {
        background: $elev-06;
        border-radius: 4px;
        font-size: 0.9rem;
        max-height: 48px;

        :global(.MuiAccordionSummary-content) {
            flex-grow: 0;
            margin: 0 auto;
        }

        &:global(.Mui-expanded) {
            min-height: 48px;
            border-radius: 4px 4px 0 0;
            z-index: 2;
            @include card-box-shadow-shallow;
        }
    }

    :global(.MuiAccordionDetails-root) {
        flex-direction: column;
        max-height: 500px;
        overflow-y: auto;
        position: relative;
        background: inherit;
        padding-top: 0;

        .timelineFilters {
            position: sticky;
            top: 0;
            background: $elev-02;
            z-index: 1;
            padding: 12px 0;

            :global(.MuiFormLabel-root) {
                @include standard-font;
                font-size: 0.8rem;
            }

            :global(.MuiInput-underline) {
                & :global(.Mui-disabled) {
                    &:before,
                    &:after {
                        border-bottom: none;
                    }
                }

                &:before,
                &:after {
                    border-bottom: 1px solid $form-control-underline;
                }

                &:hover {
                    &:not(:global(.Mui-disabled)) {
                        &:before {
                            border-bottom: 2px solid $form-control-underline;
                        }
                    }
                }
            }

            .selects {
                margin-top: 8px;
                :global(.MuiAutocomplete-root) {
                    width: 100%;
                    margin-top: 8px;

                    @include md-device {
                        width: 50%;
                        &:first-child {
                            margin-right: 16px;
                        }
                    }
                }
                @include md-device {
                    display: flex;
                }
            }
        }
    }

    :global(.MuiIconButton-root) {
        color: $highlight-2;

        svg {
            height: 1rem;
            width: 1rem;
        }
    }
}

.timelineEventRoot {
    :global(.MuiTimelineOppositeContent-root) {
        display: flex;
        flex-direction: column;
        max-width: 60px;
        padding: 8px 16px 0 0;

        > p {
            margin: 0;

            &:last-child {
                margin: 4px 0 auto auto;
                font-size: 0.7rem;
            }
        }

        @include md-device {
            max-width: 80px;
        }
    }

    :global(.MuiTimelineSeparator-root) {
        * {
            background: $highlight-1;
        }
    }

    :global(.MuiTimelineContent-root) {
        padding: 8px 16px;
        word-wrap: anywhere;
        word-break: break-all;
        > div {
            > h6,
            p {
                margin: 0;
            }

            > h6 {
                color: $highlight-2;
                margin-bottom: 4px;
                font-size: 14px;
            }

            > p {
                font-style: italic;
                font-size: 13px;
            }
        }
    }
}
