@use 'helpers' as *;

.root {
    display: flex;
    flex-direction: column;

    p {
        color: $highlight-1;
    }
    p.required {
        color: $required-field-label-color;
    }

    canvas {
        max-width: 100%;
        width: 100%;
        background: $elev-06;
        border-radius: 4px;
        max-height: 300px;
        @include xl-device {
            max-height: 200px;
        }
    }

    .clear {
        @include standard-font-family;
        color: $secondary-color;
        text-transform: none;
        font-size: 0.7rem;
        margin: 4px auto;
    }

    .generate {
        margin: -6px 6px 6px 6px;
    }
}
