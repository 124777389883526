@use 'helpers' as *;

.root {
    display: flex;
    flex-direction: column;
    width: 100%;

    > div {
        display: flex;
        flex-direction: column;
        padding: 16px;

        & p {
            @include standard-font-family;
            color: #878787;
            text-align: center;
            font-size: 1rem;
            margin: 0;
        }

        img {
            margin: auto;
            height: 100px;
        }
    }
}
