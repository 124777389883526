@use 'helpers' as *;

.root {
    :global(.MuiDialog-paper) {
        @include standard-font;
        background: $elev-04;
        :global(.MuiDialogContent-root) {
            padding-top: 24px;
        }
    }

    :global(.MuiDialogTitle-root) {
        @include standard-font;
        color: $highlight-1;
        background: $elev-06;
    }
}
