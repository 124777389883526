@use 'helpers' as *;

.root {
    &:global(.MuiFab-root) {
        color: $highlight-1;
        position: fixed;
        bottom: 24px;
        right: 24px;
        z-index: 22;
        background: $elev-24;
        @include card-box-shadow;

        &:global(.MuiFab-root:hover) {
            background: $elev-24;
        }

        @media print {
            display: none;
        }
    }
}
