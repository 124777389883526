@use 'helpers' as *;

.root {
    .dropZoneContainer {
        border-radius: 8px;
        padding: 12px;
        text-align: center;
        background: $elev-24;
        height: 60px;
        display: flex;
        flex-direction: column;
        border: 1px inset $form-control-underline;

        > div {
            outline: none;
        }

        p {
            font-size: 0.7rem;
            margin: 0 auto 8px;
            font-style: unset;
        }

        :global(.MuiCircularProgress-root) {
            margin: auto;
        }

        svg {
            color: #ffffff;

            g {
                path {
                    &:global(.fa-primary) {
                        fill: #faff8a;
                    }
                }
            }
        }
    }
}

.selectedFile {
    background: $elev-08;
    border-radius: 5px;
    padding: 0 16px;
    margin-bottom: 8px;

    :global(.MuiTypography-body1) {
        font-size: 0.9rem;
    }
}

.fileListContainer {
    text-align: center;

    p {
        color: $highlight-1;
        font-size: 0.8rem;
        margin: 8px auto 0;
    }
}
