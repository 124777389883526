@use 'helpers' as *;

@mixin card-standard {
    display: flex;
    flex-direction: column;
    * {
        margin: 0;
        @include standard-font;
        color: inherit;
    }
    .title {
        margin: 0 auto;
        
        a {
            color: $anchor-color;
        }
    }
}

.projectInfoCardRoot {
    @include card-standard;
    line-height: 1.3rem;

    @include sm-device {
        line-height: 2rem;
    }

    .healthContainer {
        padding: 24px 0;
        text-align: center;

        .healthIndicator {
            margin: 0 -16px;
            height: 20px;
            position: relative;

            p {
                font-weight: bold;
                font-size: 1.1rem;
                color: #212121;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                line-height: 1rem;
                margin: 0;
            }
        }
    }

    p {
        color: rgb(255, 255, 255);
    }

    .title {
        text-align: center;

        a {
            color: $anchor-color;
        }

    }
}

.progressCardRoot {
    @include card-standard;
}

.costCardRoot {
    @include card-standard;

    .costCardContent {
        display: flex;

        .contractValueContainer {
            width: 50%;
            display: flex;
            flex-direction: column;
            text-align: center;

            .valueGroup {
                margin: auto;

                h1 {
                    font-size: 1.5rem;
                }

                p {
                    margin-top: 12px;
                    line-height: 14px;
                    font-size: 8px;
                    color: $highlight-1;
                }
            }
        }

        :global(.apexcharts-canvas) {
            margin-left: auto;
            overflow: visible;
        }
    }
}

.contentPlaceholder {
    display: flex;
    color: $highlight-1;
    flex-grow: 1;

    :global(.MuiCircularProgress-root) {
        margin: auto;
    }
}

.title {
    font-size: 0.9rem;
    color: $highlight-1;
    font-weight: 400;

    a {
        color: $anchor-color;
    }
}

p {
    font-size: 0.65rem;
}
