@use 'helpers' as *;

.root {
    @include standard-font;
    font-size: clamp(0.75rem, 2vw, 0.9rem);
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;
    position: relative;
    line-height: 1.5rem;

    .reactionContainer {
        position: absolute;
        background: $chat-bubble;
        border-radius: 12px;
        top: -38px;
        left: 32px;
        z-index: 1;
        cursor: pointer;
    }
}

.avatar {
    margin-top: auto;
    margin-right: 8px;

    :global(.MuiAvatar-root) {
        height: 28px;
        width: 28px;
        font-weight: bolder;
        letter-spacing: 0;
        font-family: Helvetica, sans-serif;
        font-size: 0.8rem;
        background: $highlight-1;
        color: #000000;
        line-height: 1px;
    }
}

.commentContainer {
    display: flex;
}

.docLink {
    padding-right: 20px;

    .docIcon {
        position: absolute;
        bottom: 2px;
        right: 0px;
    }
}

.chatBubble {
    background: $chat-bubble;
    padding: 4px 8px;
    border-radius: 12px;
    position: relative;
    box-shadow: 4px 2px 3px -3px #000;

    &::before {
        content: '';
        position: absolute;
        left: -18px;
        bottom: 0;
        width: 20px;
        height: 10px;
        background: transparent;
        border-radius: 0 0 10px 0;
        box-shadow: 15px 0 0 0 $chat-bubble;
    }
}

.timestamp {
    font-size: 0.5rem;
    margin: auto 0 0 8px;
    color: $timestamp;
}

.respondedCheckbox {
    margin: auto 0 8px 4px;
    font-size: 0.5rem;
    color: $timestamp;
}

.blockQuote {
    border-left: 5px solid #2b4d59;

    &:hover {
        cursor: pointer;
    }
}

.sourceLinkContainer {
    text-align: left;

    a,
    a:visited,
    a:hover,
    a:active {
        color: $chat-anchor-color;
        text-decoration: none;
        font-size: clamp(0.6rem, 2vw, 0.8rem);
    }
}

.dateSection {
    display: flex; 
}

.root.currentUserMsg {
    flex-direction: row-reverse;

    .commentContainer {
        flex-direction: row-reverse;
    }

    .avatar {
        margin: 0 0 0 8px;
    }

    .chatBubble {
        background: $chat-bubble-alt;
        position: relative;

        &::before {
            display: none;
        }

        &::after {
            content: '';
            position: absolute;
            right: -18px;
            bottom: 0;
            width: 20px;
            height: 10px;
            background: transparent;
            border-radius: 0 0 0 10px;
            box-shadow: -15px 0 0 0 $chat-bubble-alt;
        }
    }

    .timestamp {
        margin: auto 8px 0 0;
    }

    .respondedCheckbox {
        margin: auto 4px 8px 0;
    }

    .sourceLinkContainer {
        text-align: right;
    }
}
