@use 'helpers' as *;

.root {
    &:global(.MuiFab-root) {
        color: $highlight-1;
        position: fixed;
        bottom: 100px;
        right: 24px;
        z-index: 22;
        background: $elev-24;
        @include card-box-shadow;

        &:global(.MuiFab-root:hover) {
            background: $elev-24;
        }

        @media print {
            display: none;
        }
    }
}

.smileFaceIcon {
    color: $positive;
}

.frownFaceIcon {
    color: $negative;
}

.paperPlaneIcon {
    color: $save-button-color;
}

.zoomCard {
    @include md-device {
        width: 350px;
        height: 520px;
        position: fixed;
        right: 0;
        bottom: 50px;
        padding: 24px;
    }

    .feedbackList {
        background-color: $elev-02;
    }

    .listSubheader {
        background-color: $elev-01;
        @include standard-font;
        font-size: 24px;
        padding: 10px 30px;
        text-align: center;
        color: $highlight-1;

        @include md-device {
            text-align: left;
        }
    }

    .primaryText {
        color: $highlight-1;
        font-size: 1rem;
        margin: 0;
    }

    span {
        @include standard-font;
    }

    p {
        @include standard-font;
        text-transform: none;
    }
}
