@use 'helpers' as *;

.root {
    display: flex;
    padding: 4px;
}

.chatReaction {
    margin-left: 4px;
    position: relative;

    g {
        & :global(.fa-secondary) {
            opacity: 1;
        }

        & :global(.fa-primary) {
            color: #262626;
        }
    }
}
