@use 'helpers' as *;

.contact {
    font-size: 0.8rem;
    color: $anchor-color;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.poc {
    width: 100%;
    display: flex;
    justify-content: center;
}
