@use 'helpers' as *;

.root {
    display: inline-flex;
    background-color: inherit;
    padding: 8px 0;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    text-align: center;
    justify-content: center;
}

@mixin single-width {
    width: 155px;
    aspect-ratio: 1.3;

    @supports not (aspect-ratio: 1) {
        &::before {
            float: left;
            padding-top: 100%;
            content: '';
        }

        &::after {
            display: block;
            content: '';
            clear: both;
        }
    }
}

.grid {
    background-color: $kpi-grid;
    border-radius: 12px;
    padding: 8px;
    overflow: hidden;
    position: relative;
    display: inline-block;
    @include card-box-shadow-shallow;
    @include single-width;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-block-start: 0;
        margin-block-end: 0;
    }
}

.clickable {
    border: 2px inset rgba(255, 213, 96, 0.6);
    @include shine-animation(#f1ce7099);
    cursor: pointer;
}
