@use 'helpers' as *;

@mixin card-standard {
    display: flex;
    flex-direction: column;
}

.invoiceCardRoot {
    @include card-standard;
}

.title {
    @include standard-font;
    margin: 0 auto;
    font-size: 0.9rem;
    color: $highlight-1;
    font-weight: 400;
}
