@use 'helpers' as *;

.root {
    height: 700px;
    width: 100%;
    padding-bottom: 40px;

    :global(.MuiDataGrid-root) {
        background: $elev-02;
        @include standard-font;
        @include card-box-shadow;
        border: 1px solid #000;
        border-radius: 12px;

        :global(.MuiDataGrid-cell) {
            padding: 0 16px;
            font-size: 12px;
        }

        @include scrollbar-styles;
        ::-webkit-scrollbar {
            max-height: 10px;
            height: 10px;
            max-width: 10px;
            width: 10px;
        }
        ::-webkit-scrollbar-button {
            display: none;
        }
    
    }

    :global(.MuiDataGrid-columnHeaders) {
        @include standard-font;
        font-size: 13px;
        color: $highlight-1;
        background: $elev-12;
        border-bottom: 1px solid $highlight-1;
        @include card-box-shadow;
        z-index: 9;
        border-radius: 12px 12px 0 0;
    }

    :global(.MuiDataGrid-footerContainer) {
        background: $elev-12;
        border-top: 1px solid $highlight-1;
        border-radius: 0 0 12px 12px;
    }
}

// Filter portal
:global(.MuiDataGrid-panel) {
    padding: 12px;

    > :global(.MuiPaper-root) {
        padding: 16px;
        @include opaque-glass;

        :global(.MuiAutocomplete-root) {
            :global(.MuiInput-root) {
                margin-top: 17px;
            }
        }

        :global(.MuiInput-root) {
            padding: 0 12px;

            &:hover {
                &::before {
                    border-bottom-color: $highlight-1;
                }
            }
        }
    }
}

.header {
    @include bold-header;
    text-align: center;
}

.statusChip {
    &:global(.MuiChip-root) {
        @include standard-font;
        font-size: 10px;
    }

    @include card-box-shadow-shallow;
}

.searchContainer {
    width: 280px;
    min-width: 280px;
    margin-right: 20px !important;

    .searchBar {
        padding: 4px 4px 4px 12px;
    }
}
