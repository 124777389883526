@use 'helpers' as *;

@mixin card-standard-style {
    display: flex;
    flex-direction: column;
}

.root {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.flexContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
}

.title {
    @include standard-font;
    margin: 0;
    font-size: 0.9rem;
    color: $highlight-1;
}

.donutChartCard {
    @include card-standard-style;
    position: relative;
    height: 100%;
    .title {
        margin: 0 auto 4px;

        @include md-device {
            margin: 0 auto 15px;
        }
    }
}
