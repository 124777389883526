@use 'helpers' as *;
@use './helpers/colors';

.root {
    .menuButton {
        @include flat-button;
        background-color: transparent;
        width: 100px;
       
        &:hover {
            color: $highlight-1;
            background-color: transparent;
            transition: color 0.3s ease-in-out;
            box-shadow: none;
        }

        &.open {
            color: $highlight-1;
        }
    }
}

.popper {
    z-index: 100;
    $popper-bg-color: colors.$elev-16;
    border-radius: 0;
    background-color: $popper-bg-color;
    box-shadow: 5px 5px 10px -3px #000;
    .arrow {
        @include arrow-top-right($popper-bg-color);
    }

}

.menuContainer {
    @include menuContainer;
}

.singleWidthMenuItem {
    @include menuItem(1);
}

.doubleWidthMenuItem {
    @include menuItem(2);
}
