@use 'helpers' as *;

@mixin card-standard {
    display: flex;
    flex-direction: column;
    * {
        margin: 0;
        @include standard-font;
        color: inherit;
    }
    .title {
        margin: 0 auto;
    }
}

.financeCardRoot {
    @include card-standard;

    .financeCardContent {
        display: flex;
        justify-content: center;

        .container {
            width: 50%;
            display: flex;
            flex-direction: column;
            text-align: center;

            .valueGroup {
                margin: auto;

                h1 {
                    font-size: 1.5rem;
                }

                p {
                    margin-top: 12px;
                    line-height: 14px;
                    font-size: 8px;
                    color: $highlight-1;
                }
            }

            .plus {
                font-size: 1rem;
            }
        }

        .twoRow {
            @extend .container;
            margin-top: 5%;
            border-right: 1px solid $primary-color;

            .valueGroup {
                margin: 5% auto;
            }
        }
    }
}

.title {
    font-size: 0.9rem;
    color: $highlight-1;
    font-weight: 400;
    a {
        color: $anchor-color;
    }
}
