@use 'helpers' as *;
.root {
    background: transparent;
    padding: 0;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    color: inherit;
}
