@use 'helpers' as *;

.root {
    .header {
        @include bold-header;
        text-align: center;
        margin: 32px 0 4px;
    }

    :global(.MuiGridListTileBar-title) {
        @include standard-font;
        font-size: clamp(0.7rem, 2vw, 1rem);
        line-height: 1rem;
        color: $highlight-1;
    }

    :global(.MuiGridListTileBar-subtitle) {
        @include standard-font;
        white-space: break-spaces;

        .description {
            font-size: clamp(0.5rem, 2vw, 0.7rem);
            margin: 6px 0 0;
        }

        .timestamp {
            font-size: clamp(6px, 2vw, 9px);
            margin: 4px 0 0 auto;
        }
    }

    :global(.MuiImageListItem-root) {
        cursor: pointer;
    }
}

.photoDetailDialog {
    &:global(.MuiModal-root) {
        :global(.MuiPaper-root) {
            background-color: transparent;
            overflow: hidden;
            box-shadow: none;
        }
    }

    :global(.MuiBackdrop-root) {
        background: rgba(0, 0, 0, 0.9);
    }

    img {
        background: none;
        object-fit: contain;
        max-height: 600px; // Required otherwise image might not show up on iOS
    }

    .captionContainer {
        @include standard-font;
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        height: 125px;
        padding: 8px 16px;
        background: rgba(0, 0, 0, 0.9);
        display: flex;

        > h3 {
            color: $highlight-1;
        }

        > p {
            margin: 0;
        }

        .description {
            font-size: clamp(0.8rem, 2vw, 0.9rem);
            margin: 8px 0;
        }

        .deleteButtonContainer {
            margin: auto 24px 16px auto;
        }
    }
}
