@use 'helpers' as *;

.root {
    display: flex;
    width: 100%;
    @include standard-font;
    line-height: 0.8rem;

    .blockQuote {
        padding: 0 20px;
        border-left: 4px solid $highlight-1;
        margin: 8px 0 8px 16px;
    }

    .comment {
        font-style: italic;
        color: $highlight-1;
        font-size: 0.7rem;
        margin: 8px 0;

        button {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
        }
    }

    .caption {
        color: $highlight-1;
        font-size: 0.5rem;
        margin: 0;
    }

    .clearButton {
        margin: auto 0 auto auto;
        color: $highlight-1;
    }
}
