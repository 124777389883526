@use 'helpers' as *;

.root {
    height: calc(100vh - 50px);
    background-image: url('../assets/img/library.jpg');
    background-size: cover;

    .content {
        padding: 24px;
        width: 100%;
        margin-left: auto;
        @include lg-device {
            width: 55%;
        }
    }

    .header {
        font-size: 1.5rem;
        margin-top: 0;
    }
}

.libraryCard {
    margin-top: 24px;
    margin-right: 24px;

    :global(.MuiCard-root) {
        box-shadow: none;
    }

    .cardContent {
        height: 100%;
        background: whitesmoke;

        > p {
            color: black;
        }

        .title {
            @include standard-font;
            font-size: 1.25rem;
            color: #000000;
        }

        .description {
            color: #4e525a;
        }
    }

    :global(.MuiCardMedia-img) {
        object-fit: fill;
        background: #f5f5f5ff;
    }
}
