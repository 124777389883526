@use 'helpers' as *;

.root {
    padding: 0 32px 32px;
    @include sm-device {
        padding: 0 64px 64px;
    }
}

.header {
    @include standard-font;
    font-size: 1.5rem;
}

.subheader {
    @include standard-font;
    font-size: 1.2rem;
    margin: 12px 0;
    color: $highlight-1;
}

.anchor {
    color: $highlight-1;
    text-decoration: none;
}

.deliveryMethodContainer {
    display: block;
    > p {
        font-size: 0.7rem;
    }

    .deliveryMethodOption {
        width: 100%;

        * {
            @include standard-font;
            font-size: 1rem;
        }
    }
}

.categoriesContainer {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    > p {
        font-size: 0.7rem;
    }
}

.categorySwitch {
    width: fit-content;
    :global(.MuiSwitch-root) {
        width: 38px;
        height: 20px;
        padding: 0;
        margin: 10px;
        overflow: visible;
    }

    :global(.MuiSwitch-switchBase) {
        padding: 1px;

        &:global(.Mui-checked) {
            transform: translateX(16px);
            color: white;

            + :global(.MuiSwitch-track) {
                background: #52d869;
                opacity: 1;
                border: 1px solid #3fa550;
            }
        }
    }

    :global(.MuiSwitch-thumb) {
        height: 20px;
        width: 20px;
    }

    :global(.MuiSwitch-track) {
        border-radius: 13px;
        border: 1px solid $highlight-1;
        background: $highlight-3;
        opacity: 0.75;
    }
}
