@use 'helpers' as *;

.root {
    padding: 16px;
    background: $elev-02;
    border-radius: 6px;
    min-height: 210px;
    width: calc(100% - 36px);
    @include sm-device {
        width: 420px;
    }
    @include card-box-shadow;
}
