@use 'helpers' as *;
.root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    h4 {
        @include standard-font;
        margin: auto auto 16px;
        color: $highlight-1;
        font-size: 1.25rem;
    }
}

@keyframes FadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.container {
    position: relative;
    height: 10vh;
}

.circles {
    display: flex;
    margin: 0 auto;
    :not(:last-child) {
        margin-right: 12px;
    }

    :global(.MuiSkeleton-root) {
        width: 8px;
        height: 8px;
        background-color: $highlight-1;
        animation: FadeIn 1.5s ease-in-out;
        animation-iteration-count: infinite;
    }

    span:nth-child(1) {
        animation-delay: 1.25s;
    }
    span:nth-child(2) {
        animation-delay: 1.5s;
    }
    span:nth-child(3) {
        animation-delay: 1.75s;
    }
}
