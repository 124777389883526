@use 'helpers' as *;

.root {
    padding: 0 16px 16px;

    :global(.MuiSlider-root) {
        margin-top: 20px;
        border-radius: 15px;
        box-sizing: content-box;
        display: inline-block;
        position: relative;
        cursor: pointer;
        touch-action: none;
        -webkit-tap-highlight-color: transparent;
        height: 20px;
        width: 90%;
        padding: 13px 0;
        margin-bottom: 20px;
    }

    :global(.MuiSlider-rail) {
        opacity: 1;
        background-image: linear-gradient(90deg, #d4202b, #ea1c24, #ed3b23, #ed7b17, #f7b318, #f7dd04, #c9db2a, #8dc740, #4cb748, #12ad4b);
        //background-image: linear-gradient(90deg, red, orange, yellow, green, blue, navy, purple, #8dc740, #4cb748, #12ad4b);
    }

    :global(.MuiSlider-markLabel) {
        padding-top: 10px;
        @include standard-font;
        font-size: 20px;
    }

    :global(.MuiSlider-markLabel) {
        padding-top: 10px;
        @include standard-font;
        font-size: 20px;
    }

    :global(.MuiSlider-mark) {
        &[data-index='0'] {
            background-color: transparent;
        }

        &[data-index='9'] {
            background-color: transparent;
        }

        width: 2px;
        height: 15px;
        border-radius: 1px;
        background-color: white;
    }

    :global(.MuiSlider-valueLabel) {
        line-height: 1.2;
        font-size: 16px;
        padding: 0;
        width: 32px;
        height: 32px;
        border-radius: 50% 50% 50% 50%;
        background-color: #52af77;
    }

    :global(.MuiSlider-thumb) {
        height: 24px;
        width: 24px;
        background: white;
        border: 2px solid #52af77;
    }

    @include md-device {
        text-align: center;
        width: 35%;
        margin: 5% auto;
    }

    .header {
        @include standard-font;
        font-size: clamp(1em, 2vw, 1.3em);
        color: $highlight-1;
        text-align: center;
    }

    .subheader {
        @include standard-font;
        font-size: clamp(1em, 2vw, 1.3em);
        margin: 12px 0;
        text-align: center;
    }

    .description {
        @include standard-font;
        font-size: clamp(0.75em, 2vw, 0.9em);
        margin: 12px 0;
    }

    .thankYou {
        :global(.MuiTypography-root) {
            @include standard-font;
        }

        :global(.MuiTypography-h4) {
            font-size: 1.2rem;
            margin-bottom: 16px;
        }

        :global(.MuiTypography-subtitle1) {
            font-size: 0.9rem;
        }
    }

    .submit {
        text-align: center;
    }

    .ratingStarBox {
        text-align: center;
    }

    .ratingStars {
        :global(.MuiRating-label) {
            font-size: 3rem;
        }

        :global(.MuiRating-iconEmpty) {
            color: $highlight-3;
        }
    }

    .commentsBox {
        :global(.MuiInputBase-root) {
            background: $elev-02;
            border-radius: 12px;
            border: hidden;
        }
    }

    .satisfactionRadioGroup {
        justify-content: center;
        gap: 16px;

        :global(.MuiRadio-root) {
            color: $highlight-1;
            margin-right: 8px;
        }
    }

    :global(.save-button) {
        width: 200px;
    }
}
