@use '../../../styles/helpers' as *;

.imageBox {
    width: 100%;
    min-height: 150px;
    background-image: url(../../../media/tech2.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    display: flex;
    @include sm-device {
        height: 100%;
        min-width: 220px;
    }
    @include md-device {
        min-width: 260px;
    }
}

.title {
    @include standard-font;
    text-align: center;
    font-weight: bold;
    font-size: 2.5rem;
    transition: font-size 0.5s ease;
    margin: auto;
}
