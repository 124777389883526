@use 'helpers' as *;

.root {
    @include scrollbar-styles;
    @include standard-font;
    background: $elev-02;

    .notificationIcon {
        width: 28px;
        height: 28px;
    }
    :global(.MuiBadge-badge) {
        min-width: 18px;
        min-height: 18px;
        height: 18px;
        font-size: 11px;
        padding: 2px 6px 2px 5px;
        z-index: 0;
        top: 0;
    }
}

.notificationCollapse {
    position: absolute;
    width: 100vw;
    top: 50px;
    right: 0;
    background: #000000ea;
    display: flex;
    flex-direction: column;

    .content {
        min-height: calc(100vh - 50px);
        max-height: calc(100vh - 50px);
        flex-grow: 1;
        padding: 12px 20px 24px 6px;
        display: flex;
        background: #000000ea;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
    }

    @include sm-device {
        @include card-box-shadow-shallow-left;
        max-width: 500px;
    }
}

.closeButton:global(.MuiIconButton-root) {
    color: $highlight-1;
    margin: auto auto 68px;
}

.noNotifications {
    display: flex;

    > p {
        margin: 36px auto;
        font-size: 1rem;
        color: #a7a7a7;
    }
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin: 0px;
    > h6 {
        @include standard-font;
        font-size: 1.2rem;
        margin: 0px;
    }
}
.buttonContainer {
    display: flex;
    height: 20px;
    gap: 8px;
}

