@use 'helpers' as *;

.root {
    @include scrollbar-styles;
    position: sticky;
    margin-left: 0px !important;
    .menuButton {
        position: sticky;
        margin: 0;
    }

    .userAvatar {
        width: 36px; 
        height: 36px;
        color: #fff;
        background-color: rgb(80, 114, 81);
        font-size: 16px;
    }

    .iconButton {
        margin-left: 0px;
    }

    
    
}

