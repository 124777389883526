@use 'helpers' as *;

.root {
    @include scrollbar-styles;

    :global(.MuiDialog-paper) {
        border-radius: 0;
        display: flex;
    }

    :global(.MuiBackdrop-root) {
        backdrop-filter: blur(2px);
    }

    .header {
        padding: 16px 24px;
        @include standard-font;
        color: $highlight-1;

        > h4,
        > div {
            margin: 6px 0;
        }

        > p {
            margin: 0;
            text-align: left;
            color: $secondary-color;
        }
    }

    :global(.MuiDialogContent-root) {
        padding: 24px;
    }

    form {
        @include dialog-module-form;
        background: $elev-04;
    }

    .newContact {
        margin: 0;
        > span {
            font-size: 0.8rem;
            color: $highlight-2;
        }
    }
}
:global(.MuiDialogActions-root) {
    .iconButtonContainer {
        margin-right: auto;
    }
}
