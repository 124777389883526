@use 'helpers' as *;

.root {
    display: flex;
    padding: 4px 0;
}

.iconContainer {
    display: block;
    line-height: 0.6rem;
}

.chatReaction {
    g {
        & :global(.fa-secondary) {
            opacity: 1;
        }

        & :global(.fa-primary) {
            color: #262626;
        }
    }
}

.reactionSource {
    letter-spacing: 0;
    font-size: clamp(0.5rem, 2vw, 0.75rem);
    margin: 0 auto;
    font-weight: bold;
}
