@use 'helpers' as *;

:root {
    overflow-x: hidden; // Kinda ghetto, figure out what's creating the horizontal scroll bar later
    background-color: $elev-00;
    @media print {
        background-color: #ffffff;
    }
}

body {
    margin: 0;
    margin-top: 50px;
    height: 100%;
    width: 100vw !important;
    background-color: $elev-00;
    @media print {
        background-color: #ffffff;
    }
    &:after {
        // This adds a background color to the overscroll area on mobile,
        // without it BG is white when user drags up
        content: '';
        position: fixed;
        top: -50%;
        right: -50%;
        left: -50%;
        bottom: -50%;
        z-index: -1;
        background-color: $elev-00;
        @media print {
            display: none;
        }
    }
}

.App {
    background-color: $elev-00;
    min-height: 100vh;
    min-width: 100vw;
    @include scrollbar-styles;
    @include standard-font;

    // Apex charts global overrides
    .apexcharts-legend-marker {
        margin: auto 6px;
    }

    // Material-UI overrides

    .MuiOutlinedInput-root {
        @mixin border-style {
            border-color: $outlined-text-field-border-color;
            border-width: 1px;
        }
        @include border-style;

        &.Mui-focused,
        &:hover {
            @include border-style;

            .MuiOutlinedInput-notchedOutline {
                @include border-style;
            }
        }

        .MuiOutlinedInput-notchedOutline {
            @include border-style;
        }
    }

    .MuiInput-root {
        @mixin border-style {
            border-color: $outlined-text-field-border-color;
            border-width: 1px;
        }

        .MuiInput-underline:before {
            @include border-style;
        }

        &.Mui-focused,
        &:hover {
            @include border-style;
        }
    }

    .MuiFormLabel-root.Mui-focused {
        color: inherit;
    }

    .MuiInput-underline,
    .MuiInput-underline.Mui-disabled {
        &:before {
            border-bottom: 1px solid $form-control-underline;
        }

        &:after {
            border-color: $highlight-1;
        }

        &:hover {
            &:not(.Mui-disabled) {
                &:before {
                    border-color: $highlight-1;
                }
            }

            &:before,
            &:after {
                border-color: $highlight-1;
            }
        }
    }

    .MuiAutocomplete-popper {
        .MuiPaper-root {
            background: $elev-24;
            @include standard-font;
            font-size: 0.7rem;
        }

        .MuiAutocomplete-noOptions {
            @include standard-font;
        }

        .MuiAutocomplete-option[aria-selected='true'] {
            background-color: $autocomplete-selected;
        }
    }

    .MuiInputBase-root {
        color: $primary-color;
    }

    .MuiPickersModal-dialogRoot {
        background: $elev-02;
        color: $primary-color;

        .MuiPickersToolbar-toolbar,
        .MuiDialogActions-root {
            background: $elev-08;
        }

        .MuiButton-textPrimary,
        .MuiIconButton-root {
            color: $primary-color;
        }

        .MuiPickersCalendarHeader-dayLabel,
        .MuiPickersYear-yearSelected {
            color: $highlight-1;
        }

        .MuiPickersCalendarHeader-iconButton {
            background: transparent;
        }

        .MuiPickersDay-daySelected {
            background: $highlight-1;
            color: #000000;
        }

        .MuiPickersModal-dialog {
            background: $elev-02;
            color: $primary-color;
        }
    }

    .MuiFab-root {
        border-radius: 50%; // Only necessary because Material Table is injecting it's stylesheet last
    }
}

.MuiChip-root {
    border-radius: 16px !important; // Only necessary because Material Table is injecting it's stylesheet last
}

.MuiTooltip-popper {
    .MuiTooltip-tooltip {
        $border-color: rgba(109, 133, 215, 0.5);
        @include standard-font;
        font-size: 12px;
        border-radius: 4px;
        border: 1px solid $border-color;
        padding: 10px 16px;
        background: rgba(109, 133, 215, 0.11);
        backdrop-filter: blur(10px);

        > div {
            display: flex;
            gap: 12px;
            align-items: center;
        }

        .MuiTooltip-arrow {
            color: $border-color;
        }
    }
}

.MuiModal-root {
    .MuiPaper-root {
        background: $elev-02;
    }
}

.MuiAutocomplete-popper {
    .MuiPaper-root {
        background: $elev-24;
        @include standard-font;
        font-size: 0.7rem;
    }

    .MuiAutocomplete-noOptions {
        @include standard-font;
    }

    .MuiAutocomplete-option[aria-selected='true'] {
        background-color: $autocomplete-selected;
    }
}

.MuiAutocomplete-root {
    .MuiAutocomplete-endAdornment {
        top: -14px;
    }
}

.MuiTextField-root {
    .MuiInput-underline {
        &:before,
        &:after {
            border-color: $form-control-underline;
        }

        &:hover {
            &:not(.Mui-disabled) {
                &:before {
                    border-color: $highlight-1;
                }
            }
        }
    }
}

// Snackbar Overrides
.snack-root {
    > div {
        padding-right: 32px;
    }
}

// Globals
.add-button,
.add-button.MuiButton-root {
    @include standard-font-family;
    color: $add-button-color;
    font-size: 11px;
    letter-spacing: 1px;
    position: relative;
    text-align: end;
    padding: 6px 8px 6px 32px;

    @include md-device {
        margin-left: 5px;
    }

    // Plus icon
    :before,
    :after {
        content: '';
        position: absolute;
        height: 5px;
        width: 5px;
    }

    :before {
        border-bottom: 2px solid $add-button-color;
        border-left: 2px solid $add-button-color;
        left: 13px;
        top: 9px;
    }

    :after {
        border-top: 2px solid $add-button-color;
        border-right: 2px solid $add-button-color;
        top: 15px;
        left: 8px;
        transform: translateY(-1px);
    }

    @include draw-border-animation($add-button-color);
}

.close-button,
.close-button.MuiButton-root {
    @include standard-font-family;
    color: $primary-color;
    font-size: 0.8rem;
    letter-spacing: 1px;
    padding: 4px 12px;
}

.print-button,
.print-button.MuiButton-root {
    @include standard-font-family;
    color: $print-button-color;
    font-size: 0.8rem;
    letter-spacing: 1px;
    @include draw-border-animation($print-button-color);
}

.react-hook-form-controller-container {
    position: relative;
    background: inherit;

    .react-hook-form-error-message {
        @include standard-font-family;
        color: $secondary-color;
        margin: 0;
        line-height: 1rem;
        position: absolute;
        bottom: -16px;
        right: 0;
    }
}

.download-button {
    img {
        height: 24px;
    }
}

.preview-button {
    padding: 8px;

    img {
        height: 22px;
    }
}

.save-button,
.save-button.MuiButton-root {
    @include standard-font-family;
    color: $save-button-color;
    font-size: 0.8rem;
    letter-spacing: 1px;
    padding: 4px 12px;
    @include draw-border-animation($save-button-color);
}

.delete-button {
    padding: 8px;

    img {
        height: 22px;
    }
}

//// Global overrides
h1 {
    font-size: 1.2rem;
}

h2 {
    font-size: 1.1rem;
}

h3 {
    font-size: 1rem;
}

h4 {
    font-size: 0.9rem;
}

h5 {
    font-size: 0.8rem;
}

h6 {
    font-size: clamp(0.7rem, 2vw, 0.8rem);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

p,
li {
    font-size: clamp(0.65rem, 2vw, 0.75rem);
    line-height: 1.3rem;
    margin-block: 0;
}

// This is stop mobile from auto-zooming when clicking on an input, min font-size is 16px to prevent this behavior
input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
    font-size: 16px;
}

.swipe-slide {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
}

.swiper-wrapper {
    -webkit-transform-style: preserve-3d;
}

// Desktop Scrollbar styling
::-webkit-scrollbar {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background-color: $scroll-bar-track-color;
}

::-webkit-scrollbar-thumb {
    background-color: $scroll-bar-thumb-color;
    border-radius: 8px;
}

::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}

// Masonry
.my-masonry-grid {
    display: flex;
    margin-left: -16px; /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 16px; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 16px;
}
