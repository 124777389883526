@use 'helpers' as *;

.root {
    padding: 16px;
    background: $elev-02;
    border-radius: 6px;
    height: 268px;
    max-width: 420px;
    width: 100%;
    @include card-box-shadow;
    @include sm-device {
        width: 420px;
    }
}
